import {Checkbox} from 'primereact/checkbox'
import React, {useEffect} from 'react'
import { ErrorMessage, useField } from 'formik'

const CheckboxEditor = (
	{
		fieldName,
		fieldLabel,
		tooltip,
		children,
		required=false,
		onChange
	}) => {
	const [field, meta, helper] = useField(fieldName)

	useEffect(()=>{
		if (![true, false].includes(field.value)) {
			helper.setValue(null)
		}
		// eslint-disable-next-line
	}, [field.value])

	const onCheckChange = (e) => {
		if (onChange)
		{
			onChange(e.checked)
		}
		helper.setValue(e.checked)
	}
	return (
		<div className='form-group'>
			<div className='d-flex align-items-center'>
				{ [true, false, null].includes(field.value) && <Checkbox
					id={field.name}
					key={'input' + fieldName}
					className={`form-check-input ${meta.error && meta.touched ? 'is-invalid' : ''}`}
					inputId={'input' + fieldName}
					value={field.value}
					onChange={onCheckChange}
					tooltip={tooltip}
					checked={field.value}
					required={required}
				/>}
				<label
					key={'label' + fieldName}
					htmlFor={'input' + fieldName}
					className={`p-checkbox-label ${required ? 'required' : ''}`}>
					{fieldLabel}
					{children}
				</label>
			</div>
			<ErrorMessage name={fieldName} component="div" className="error-message" />
		</div>
	)
}

export default CheckboxEditor
