import React, {useContext, useState} from 'react'
import {Link, withRouter} from 'react-router-dom'
import gql from 'graphql-tag'
import {getEnumValue} from '../routes/EnumUtils'
import {UserContext} from '../components/UserContext'
import {useQuery} from '@apollo/client'

export const GET_SOROZAT = gql`
    query GetSorozatById($sorozatId: ID!) {
        getSorozatById(sorozatId: $sorozatId) {
            id
            nev
            penznem
        }
    }
`
const BreadcrumbContent = ({withLink, needBackButton, children, history, match, value}) => {
	let sorozatNev = value ? value.nev : ''
	let sorozatId = match.params.sorozatId
	let penznemKey = match.params.penznem ? match.params.penznem : (value ? value.penznem : '')
	let penznemValue = penznemKey ? getEnumValue('Penznem', penznemKey.toUpperCase()) : ''
	let params = history.location ? new URLSearchParams(history.location.search) : ''
	let searchText = (params.get('searchText') !== null && params.get('searchText').length !== 0) ? params.get('searchText') : ''
	searchText = searchText.length !== 0 ? searchText : ((sessionStorage.getItem('bcSearchText')
		!== null
		&& sessionStorage.getItem('bcSearchText').length
		!== 0) ? sessionStorage.getItem('bcSearchText') : '')
	let wasSearch = searchText.length !== 0
	return (
		<div className="breadcrumb-comp">
			<div className="row align-items-baseline">
				<div className="col">{((sorozatId && penznemKey) || wasSearch) ?
					<nav aria-label="breadcrumb">
						<ol className="breadcrumb">
							{wasSearch ?
								<li className="breadcrumb-item " aria-current="page">Keresés</li>
								:
								<>
									<li className="breadcrumb-item">
										{penznemValue}
									</li>
									<li className="breadcrumb-item">
										{withLink ? <Link to={`/lista/${penznemKey}/${sorozatId}`}>{sorozatNev}</Link> : sorozatNev}
									</li>
								</>
							}
						</ol>
					</nav> : ''}</div>
				<div className="col-auto">
					{needBackButton &&
					<button className="back-link green-button cursor-pointer mb-1" onClick={() => {
						if (history.location.pathname === '/lista') {
							if (wasSearch) {
								history.replace({
									pathname: '/lista/',
									search: ('?searchText=' + searchText
									)
								})
							} else {
								history.replace({pathname: '/lista/' + penznemKey + '/' + sorozatId})
							}
						} else {
							if (wasSearch) {
								history.push({
									pathname: '/lista',
									search: ('?searchText=' + searchText
									)
								})
							} else {
								history.push({pathname: '/lista/' + penznemKey + '/' + sorozatId})
							}
						}
					}}>
						Vissza
					</button>
					}
					{children}
				</div>
			</div>
			<hr/>
		</div>
	)
}

const Breadcrumb = ({withLink = true, needBackButton = false, children, history, match}) => {
	const userContext = useContext(UserContext)
	const [breadcrumb, setBreadCrumb] = useState(null)
	const getSorozatQuery = useQuery(GET_SOROZAT, {
		skip: !userContext.loggedInUser || !match.params.sorozatId,
		variables: {sorozatId: match.params.sorozatId},
		onCompleted(data) {
			if (data && data.getSorozatById) {
				setBreadCrumb(data.getSorozatById)
			}
		},
		onError(error) {
			console.log(JSON.stringify(error))
		}
	})

	return (
		<>
			{getSorozatQuery.loading && <>Betöltés folyamatban...</>}
			{getSorozatQuery.error && <div>Hiba a betöltés közben! {console.log(getSorozatQuery.error)}</div>}
			{breadcrumb ?
				<BreadcrumbContent value={breadcrumb} history={history} children={children} match={match} withLink={withLink} needBackButton={needBackButton}/> :
				<BreadcrumbContent history={history} children={children} match={match} withLink={false} needBackButton={needBackButton}/>
			}
			{/*{match.params.sorozatId ?*/}
			{/*	<Query query={GET_SOROZAT} skip={!userContext.loggedInUser} variables={{sorozatId: match.params.sorozatId}}>*/}
			{/*		{(result) => {*/}
			{/*			try {*/}
			{/*				if (result.data && (result.data.loading || !result.data.getSorozatById)) {*/}
			{/*					return <div></div>*/}
			{/*				} else if (!result || result.error) {*/}
			{/*					return <div>Hiba a betöltés közben! {console.log(result)}</div>*/}
			{/*				} else {*/}
			{/*					return <>{result && result.data ? <BreadcrumbContent value={result.data.getSorozatById}*/}
			{/*						history={history} children={children} match={match} withLink={withLink} needBackButton={needBackButton}/> : ''}</>*/}
			{/*				}*/}
			{/*			}*/}
			{/*			catch (e) {*/}
			{/*				console.log(JSON.stringify(e))*/}
			{/*				// userContext.growl({ severity: 'error', summary: t('Provisioning:upload-form.error-message.error-message') })*/}
			{/*			}*/}
			{/*		}}*/}
			{/*	</Query> :*/}
			{/*	<BreadcrumbContent history={history} children={children} match={match} withLink={false} needBackButton={needBackButton}/>*/}
			{/*}*/}
		</>
	)
}
export default withRouter(Breadcrumb)