import React, {useContext, useRef} from 'react'
import {UserContext} from '../../components/UserContext'
import {Formik} from 'formik'
import {Redirect} from 'react-router'
import {Button} from 'primereact/button'
import {Panel} from 'primereact/panel'
import {Messages} from 'primereact/messages'
import PasswordEditor from '../../components/editors/PasswordEditor'
import TextEditor from '../../components/editors/TextEditor'
import {Logo} from '../../layout/Menu'
import {Link} from 'react-router-dom'
import * as Yup from 'yup'
import {setLocale} from 'yup'

export const createErrorMessage = (error, summary, messageRef, life) => {
	if (!messageRef) {
		return createError(error)
	}
	let errorMessage = 'Ismeretlen hiba!'
	if (error && error.graphQLErrors && error.graphQLErrors.length > 0) {
		errorMessage = error.graphQLErrors[0].extensions.errorMessage
		if (messageRef && messageRef.current) {
			let errorAlreadyDisplayed = false
			messageRef.current.state.messages.map((value, index) => {
				if (value.detail === errorMessage) {
					errorAlreadyDisplayed = true
				}
				return <></>

			})
			!errorAlreadyDisplayed && messageRef.current.show({severity: 'error', summary: summary, detail: errorMessage, life: life, closable: false})
		}
	}
}

export const createErrorGrowl = (error, summary, userContext, life) => {
	let errorMessage = 'Ismeretlen hiba!'
	if (error && error.graphQLErrors && error.graphQLErrors.length > 0) {
		errorMessage = error.graphQLErrors[0].extensions.errorMessage
		userContext.growl({severity: 'error', summary: summary, detail: errorMessage, life: life, closable: false})
	}
}

const createError = (error) => {
	let errorMessage = 'Ismeretlen hiba!'
	if (error && error.graphQLErrors && error.graphQLErrors.length > 0) {
		errorMessage = error.graphQLErrors[0].extensions.errorMessage
	}
	return errorMessage
}

const LoginForm = () => {
	const messageRef = useRef(null)

	setLocale({
		mixed: {
			required: 'A mező kitöltése kötelező!'
		}
	})

	const loginValidationSchema = Yup.object().shape({
		email: Yup.string().required(),
		password: Yup.string().required()
	})
	const userContext = useContext(UserContext)
	return <>
		<Formik
			validateOnBlur={false}
			initialValues={{email: '', password: ''}}
			validationSchema={loginValidationSchema}
			onSubmit={(values, {setSubmitting}) => {
				userContext.loginUser(values.email, values.password).then((res) => {
					if (res == null) {
						if (messageRef?.current) {
							messageRef.current.clear()
							messageRef.current.show({
								severity: 'error',
								summary: 'Sikertelen belépés!',
								detail: 'Hibás felhasználónév vagy jelszó.',
								life: 80000,
								closable: false
							})
						}
					}
				}).catch(() => {
					if (messageRef?.current) {
						messageRef.current.clear()
						messageRef.current.show({
							severity: 'error',
							summary: 'Sikertelen belépés!',
							detail: 'Ismeretlen hiba.',
							life: 8000,
							closable: false
						})
					}
				})
			}}
		>
			{({
				values,
				handleSubmit
			}) => (
				<form className="form-signin mt-2"
					onSubmit={handleSubmit}
				>
					<div className="mb-3">
						<TextEditor focus fieldName="email" placeholder="e-mail cím"/>
					</div>

					<div className="mb-3">
						<PasswordEditor fieldName="password" placeholder="jelszó"/>
					</div>
					<div>
						<Button
							className=""
							label="Bejelentkezés"
							type="submit"/>
					</div>
					<Messages className="mb-3 error-message" ref={messageRef}/>
					<div className="mb-1">
						<div className="cursor-pointer text-center">
							<Link to={{pathname: '/elfelejtett_jelszo', state: {'email': values.email}}}>Jelszómódosítás igénylése</Link>
						</div>
					</div>
				</form>
			)}
		</Formik>
		<div className="mb-2 cursor-pointer text-center">
			<Link to="/regisztracio">Regisztráció</Link>
		</div>
	</>

}

const LoginPage = () => {
	const userContext = useContext(UserContext)
	return (
		<>
			{!userContext.noLoggedInUser ? <Redirect to="/"/> :
				<div className="main-page-content login-panel">
					<div className="d-flex h-100 justify-content-center flex-column">
						<div className="logo-wrapper d-flex justify-content-center">
							<div className="d-flex flex-column align-self-center">
								<div className="col"><Logo/></div>
								<div className="col mt-5">
									<div>
										<Panel>
											<LoginForm/>
										</Panel>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			}
		</>
	)
}

export default LoginPage
