import React, {useContext} from 'react'
import {Form, Formik} from 'formik'
import imgFind from '../images/find-32.png'
import {withRouter} from 'react-router-dom'
import {BankjegyContext} from '../components/BankjegyContext'

const SearchPanel = (props) => {
	let params = props.history.location ? new URLSearchParams(props.history.location.search) : ''
	let wasSearch = !(params.get('searchText') === null || params.get('searchText').length === 0)
	const bankjegyContext = useContext(BankjegyContext)
	return (
		<div className="search-panel">
			<Formik
				enableReinitialize
				initialValues={{searchText: wasSearch ? params.get('searchText') : (sessionStorage.getItem('bcSearchText') ? sessionStorage.getItem('bcSearchText') : '')}}
				validate={() => {
					let errors = {}
					return errors
				}}
				onSubmit={(values) => {
					if (values.searchText) {
						if (props.history.location.pathname === '/lista') {
							props.history.replace({pathname: '/lista/', search: ('?searchText=' + values.searchText)})
						} else {
							props.history.push({pathname: '/lista', search: ('?searchText=' + values.searchText)})
						}
						bankjegyContext.setListPageScrollPosition(0)
						bankjegyContext.setSelectedBankjegyId(undefined)
					}
				}}
			>
				{({
					values,
					handleChange,
					handleBlur,
					submitForm
				}) => (
					<Form className="search-form">
						<input
							id={'input_searchText'}
							name="searchText"
							placeholder="Keresés"
							value={values.searchText}
							onChange={handleChange}
							onBlur={handleBlur}
						/>

						<span className="cursor-pointer ml-1" onClick={() => {
							submitForm().then()
						}}>
							<img src={imgFind} alt="find"/>
						</span>
					</Form>
				)}
			</Formik>
		</div>
	)
}

export default withRouter(SearchPanel)