import React, {useContext, useRef} from 'react'
import {useMutation} from '@apollo/client'
import {createErrorMessage} from './LoginPage'
import {withRouter} from 'react-router-dom'
import gql from 'graphql-tag'
import {Formik} from 'formik'
import {Button} from 'primereact/button'
import {ProgressBar} from 'primereact/progressbar'
import {Messages} from 'primereact/messages'
import PasswordEditor from '../../components/editors/PasswordEditor'
import {UserContext} from '../../components/UserContext'
import * as Yup from 'yup'
import {setLocale} from 'yup'
import AdminPanel from '../../layout/AdminPanel'

const MODIFY_PASSWORD = gql`
    mutation ModifyPassword($jelszoModositas: JelszoModositas!) {
        modifyPassword(jelszoModositas: $jelszoModositas)
    }
`

const PasswordFormContent = withRouter(({history}) => {

	const userContext = useContext(UserContext)
	const user = userContext.loggedInUser

	const messageRef = useRef(null)
	const [modifyPassword, {loading: mutationLoading, error: mutationError}] = useMutation(MODIFY_PASSWORD)

	/* eslint-disable no-template-curly-in-string */
	setLocale({
		mixed: {
			required: 'A mező kitöltése kötelező!'
		},
		string: {
			min: 'A ${label} mezőnek minimum ${min} karakter hosszúnak kell lennie!',
			max: 'A ${label} mezőnek maximum ${max} karakter hosszúnak lehet lennie!',
			matches: 'Nem megfelelő ${label}! pl.: nev@email.hu'
		}
	})

	const passwordValidationSchema = Yup.object().shape({
		oldPassword: Yup.string().required().label('régi jelszó'),
		password: Yup.string().min(8).max(255).required().label('jelszó'),
		password2: Yup.string().required().oneOf([Yup.ref('password'), null], 'Nem egyeznek a beírt jelszavak!').label('jelszó')
	})

	return (
		<>
			<Formik
				validateOnBlur={false}
				initialValues={{oldPassword: '', password: '', password2: ''}}
				validationSchema={passwordValidationSchema}
				onSubmit={(values) => {

					modifyPassword({
						variables: {
							'jelszoModositas': {
								id: user.id,
								regiJelszo: values.oldPassword,
								ujJelszo: values.password,
								ujJelszoIsmet: values.password2
							}
						}
					}).then((data) => {
						userContext.growl({severity: 'info', summary: 'A jelszómódosítás sikeresen megtörtént!'})
						history.push('/')
					})
				}}
			>
				{({errors, handleSubmit}) => (
					<form className="form-forgotten-password"
						onSubmit={handleSubmit}
					>
						<div className="mb-3">Kérem, adja meg az jelszavát!</div>
						<div className="mb-3">
							<PasswordEditor focus fieldName="oldPassword" placeholder="régi jelszó"/>
						</div>
						<div className="mb-3">
							<PasswordEditor fieldName="password" placeholder="jelszó"/>
						</div>
						<div className="mb-3">
							<PasswordEditor fieldName="password2" placeholder="jelszó ismét"/>
						</div>
						<div className="buttons">
							<Button
								disabled={Object.keys(errors).length > 0 || mutationLoading}
								className="green-button"
								label="Jelszó módosítás"
								type="submit"/>
							<Button
								onClick={(e) => {
									history.push('/')
								}}
								className="brown-button ml-1"
								label="Mégsem"
								type="button"/>
						</div>
						{mutationLoading && <ProgressBar className="mt-3" mode="indeterminate"/>}
						{createErrorMessage(mutationError, 'Sikertelen jelszó módosítás!', messageRef, 20000)}
						<Messages className="mt-3" ref={messageRef}/>
					</form>
				)}
			</Formik>
		</>
	)
})

const PasswordForm = () => {
	return <AdminPanel label="Jelszó módosítása">
		<PasswordFormContent/>
	</AdminPanel>
}

export default PasswordForm
