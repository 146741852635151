import { Captcha } from 'primereact/captcha'
import React from 'react'
import { Field, useField } from 'formik'

const ChaptchaEditor = ({fieldName, siteKey}) => {
	const [field, meta, helper] = useField(fieldName)
	return siteKey ? (<>
			<Captcha
				siteKey={siteKey}
				id={field.name}
				onResponse={e => helper.setValue(e.response)}
				language='hu' />
			<div className={`mb-3 error-message  ${meta.error ? 'mb-2' : ''}`}>
				<Field
					name={fieldName}
					className='error-message'
					render={() => {
						return meta.error ? meta.error : null
					}}
				/>
			</div>
		</>) : null
}

export default ChaptchaEditor
