/**
 * A bal oldali menü és keresés állapotát tárolja ez a context
 */

import React, {useEffect, useRef, useState} from 'react'
import gql from "graphql-tag"
import {ApolloConsumer, useQuery} from '@apollo/client'
import {Toast} from "primereact/toast";

export const UserContext = React.createContext(null)

const GET_POSSIBLE_PENZNEM = gql`{
    getPossiblePenznem
}`

const CURRENT_USER_QUERY = gql`{
    currentUser {
        id
        email
        nev
        telefonszam
        szerep
		gyujto
		superuser
    }}`

const FetchCurrentUser = ({client, loggedInUser, children, setLoggedInUser, setNoLoggedInUser}) => {
	if (loggedInUser === null) {
		client.query({query: CURRENT_USER_QUERY}).then(
			(result) => {
				if (result.data && result.data.currentUser) {
					setLoggedInUser(result.data.currentUser)
					setNoLoggedInUser(false)
				} else {
					setNoLoggedInUser(true)
				}
			}
		).catch(
			() => {
				// TODO: nincs bejelentkezett felhasználó
				// console.log(error)
				setNoLoggedInUser(true)
			}
		)
	}
	return children
}

export const UserContextProvider = ({children}) => {
	//a bejelentkezett felhasználó
	const [loggedInUser, setLoggedInUser] = useState(null)
	//annak vizsgálatára használjuk, hogy az async vizsgálat közben épp milyen állapotban van a bejelentkezett felhasználó betöltése.
	//3 állapot lehetséges: 1) true: ekkor biztosan nincs betöltve a felhasználó (vagy még nem futott le a query vagy hibát dobott
	//2) false: biztosan lefutott a bejelentkezett felhasználó lekérdezése sikeresen
	//3) null: egyáltalán nem történt még hívás
	// ezeket az állapotokat a redirektálásnál használjuk. true esetén betölti a kezdő oldalt, false esetén betöltő az aktuális oldalt,
	// null esetén nem csinál semmit, vár amíg nem lesz betölthető oldal
	const [noLoggedInUser, setNoLoggedInUser] = useState(null)
	const [possiblePenznem, setPossiblePenznem] = useState([])
	const possiblePenznemQuery = useQuery(GET_POSSIBLE_PENZNEM, {skip:noLoggedInUser === false})
	const growlEl = useRef(null)
	useEffect(() => {
		if (loggedInUser)
		{
			possiblePenznemRefetch()
		}
	}, [noLoggedInUser])

	const possiblePenznemRefetch = () => {
		possiblePenznemQuery.refetch().then(({data})=>{
			setPossiblePenznem(data.getPossiblePenznem)
		})
	}
	return (
		<ApolloConsumer>
			{client =>
				<FetchCurrentUser client={client} loggedInUser={loggedInUser} setLoggedInUser={(user) => setLoggedInUser(user)}
								  setNoLoggedInUser={setNoLoggedInUser}>
					<Toast ref={growlEl}  position="bottom-right"/>
					<UserContext.Provider
						value={{
							loggedInUser: loggedInUser,
							noLoggedInUser: noLoggedInUser,
							loginUser: async (email, password) => {
								// eslint-disable-next-line
								const token = document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*\=\s*([^;]*).*$)|^.*$/, "$1")

								let data = new FormData();
								data.append("username", email)
								data.append("password", password)
								data.append('_csrf', token)
								await fetch("/perform_login", {
									method: 'POST',
									body: data
								}).then(async response => {
										if (response.ok)
										{
											const userData = await response.json()
											setLoggedInUser(userData)
											setNoLoggedInUser(false)
										}
										else
										{
											setLoggedInUser(null);
											setNoLoggedInUser(true)
										}
									}
								).catch(
									(error) => {
										setLoggedInUser(null)
										setNoLoggedInUser(true)
										throw error
									}
								)
								return loggedInUser
							},
							logoutUser: async () => {
								// eslint-disable-next-line
								const token = document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*\=\s*([^;]*).*$)|^.*$/, "$1")

								let data = new FormData();
								data.append('_csrf', token)
								await fetch("/logout", {
									method: 'POST',
									body: data
								}).then(async response => {
									setLoggedInUser(null)
									setNoLoggedInUser(true)
									await client.resetStore()
								})
							},
							updateUser: async () => {
								client.query({query: CURRENT_USER_QUERY, fetchPolicy: 'network-only'}).then(
									async (result) => {
										if (result.data && result.data.currentUser) {
											setLoggedInUser(result.data.currentUser)
										}
									}
								).catch(
									(error) => {
										console.log(JSON.stringify(error))
									}
								)
							},
							growl: ({severity = 'info', summary, detail, life = 8000}) => {
								growlEl
									? growlEl.current.show({severity, summary, detail, life})
									: console.log(`summary: ${summary}, detail: ${detail}`)
							},
							possiblePenznem: possiblePenznem,
							possiblePenznemRefetch:possiblePenznemRefetch
						}}
					>
						{children}
					</UserContext.Provider>
				</FetchCurrentUser>
			}
		</ApolloConsumer>
	)
}

export const UserContextConsumer = UserContext.Consumer
