import numeral from 'numeral'
import huLocale from 'moment/locale/hu'
import moment from 'moment'
import {getEnumValue} from "./EnumUtils";

export const CalendarLocale = {
	today: 'Ma',
	clear: 'Törlés',
	firstDayOfWeek: 1,
	dayNames: ['Vasárnap', 'Hétfő', 'Kedd', 'Szerda', 'Csütörtök', 'Péntek', 'Szombat'],
	dayNamesShort: ['Vas', 'Hé', 'Ke', 'Sze', 'Csüt', 'Pé', 'Szo'],
	dayNamesMin: ['V', 'H', 'K', 'Sze', 'Cs', 'P', 'Szo'],
	monthNames: ['Január', 'Február', 'Március', 'Április', 'Május', 'Június', 'Július', 'Augusztus', 'Szeptember', 'Október', 'November', 'December'],
	monthNamesShort: ['Jan', 'Feb', 'Már', 'Ápr', 'Máj', 'Jún', 'Júl', 'Aug', 'Sze', 'Okt', 'Nov', 'Dec']
}
moment.locale('hu',huLocale)
// dátum formázás: https://momentjs.com/ - Multiple Locale Support
export const formatDateTime = (val) => {
	return val === undefined || val === '' || val === null
		? '' : moment(val).format('L LTS')
}

export const formatDate = (val) => {
	let md = moment(val)
	if (val)
	{
		return md.isValid ? md.format('L') : '-'
	}
	return ''
}
export const formatDateTimeForSubmit = (val) => {
	if (val)
	{
		let md = moment(val)
		return md.isValid ? md.format() : ''
	}
	return ''
}
export const formatDateForSubmit = (val) => {
	if (val)
	{
		let md = moment(val)
		return md.isValid ? md.format('YYYY-MM-DD') : ''
	}
	return ''
}

// load a locale
numeral.register('locale', 'hu', {
	delimiters: {
		thousands: ' ',
		decimal: ','
	},
	abbreviations: {
		thousand: 'e',
		million: 'm',
		billion: 'b',
		trillion: 't'
	},
	ordinal : (number) => {
		return number === 1 ? 'er' : 'ème'
	},
	currency: {
		symbol: 'Ft'
	}
})

numeral.locale('hu')

export const formatNumber = (val) => {
	if (val === 0) {
		return numeral(val).format('0.[0]')
	} else if (val) {
		return numeral(parseFloat(val)).format('0,0.[00]') //1032154.01=>1 032 154,01; 0=>0; 1000.00000 => 1 000;
	}
	return ''
}

export const formatCurrency = ( val, unit, curr ) => {
	if (val)
	{
		return numeral(val).format('0,0') + ' ' + (unit ? unit : (curr ? getEnumValue('Penznem', curr).toLowerCase() : 'Ft'))
	}
	return ''
}

export const formatPiece = ( val ) => {
	if (val)
	{
		return numeral(val).format('0,0') + ' db'
	}
	return ''
}

export const isFormEmpty = (values) => {
	if (values) {
		return Object.values(values).every(e=>e==='' || e===null || e===undefined)
	}
	return true
}

