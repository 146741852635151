import React, {useContext, useEffect, useRef, useState} from 'react'
import gql from 'graphql-tag'
import {UserContext} from '../../components/UserContext'
import {useMutation, useQuery} from '@apollo/client'
import {createErrorGrowl} from '../user/LoginPage'
import imgEdit216 from '../../images/edit2-w-16.png'
import imgDelete16 from '../../images/trash-w-16.png'
import {Formik, useField} from 'formik'
import NumberEditor from '../../components/editors/NumberEditor'
import TextAreaEditor from '../../components/editors/TextAreaEditor'
import {Button} from 'primereact/button'
import * as Yup from 'yup'
import {setLocale} from 'yup'
import {isFormEmpty} from '../Common'
import {Dialog} from 'primereact/dialog'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {InputText} from 'primereact/inputtext'
import {Messages} from 'primereact/messages'

const GET_BANKJEGY_MEGJEGYZES = gql`
    query GetBankjegyMegjegyzes($bankjegyId: Long!, $userId: Long!) {
        getBankjegyMegjegyzes(bankjegyId: $bankjegyId, userId: $userId) {
            id
            bankjegyId
            felhasznaloId
            megjegyzes
            darabszamUnc
            darabszamEf
            darabszamVf
            bankjegyMegjegyzesBetujelek {
                id
                betujel
                darabszam
            }
        }
    }
`

const SAVE_BANKJEGY_MEGJEGYZES = gql`
    mutation SaveBankjegyMegjegyzes($bankjegyMegjegyzesMentes: BankjegyMegjegyzesMentes!) {
        saveBankjegyMegjegyzes(bankjegyMegjegyzesMentes: $bankjegyMegjegyzesMentes) {
            id
        }
    }
`
const DELETE_BANKJEGY_MEGJEGYZES = gql`
    mutation DeleteBankjegyMegjegyzes($bankjegyMegjegyzesTorles: BankjegyMegjegyzesTorles!) {
        deleteBankjegyMegjegyzes(bankjegyMegjegyzesTorles: $bankjegyMegjegyzesTorles)
    }
`

export const LabelValue = ({label, value, className}) => {
	return (
		<div className={`row collectort-label-value-comp col-12 no-gutters p-0 ${className ? className : ''}`}>
			<label htmlFor="labelValue" className="col-5 col-sm-auto col-md-4 col-lg-auto col-form-label">{label}:</label>
			<div className="col-7 col-sm col-md-8 col-lg pl-3">
				{value && <span key={`input_${value}`} className="form-control-plaintext">{value}</span>}
			</div>
		</div>
	)
}

const UNCPieceEditor = ({fieldName}) => {
	const [field, , helper] = useField(fieldName)

	const [componentValue, setComponentValue] = useState(null)

	useEffect(() => {
		if (!componentValue) {
			let initValue = []
			if (field.value && field.value.length > 0) {
				initValue = field.value.map(v => ({id: v.id, darabszam: v.darabszam, betujel: v.betujel}))
					.sort((v1, v2) => ((v1.betujel > v2.betujel) ? 1 : (v2.betujel > v1.betujel) ? -1 : 0))
				helper.setValue(initValue)
			}

			setComponentValue(initValue)
		}
		// eslint-disable-next-line
	}, [field.value])

	const onEditorValueChangeForRowEditing = (props, value) => {
		props.editorCallback(value)
		let valuesForModify = [...componentValue]
		valuesForModify[props.rowIndex][props.field] = value
		setComponentValue(valuesForModify)
		helper.setValue(valuesForModify)
	}

	return <>
		<DataTable
			autoLayout={false}
			value={componentValue}
			editMode="cell"
			className="editable-cells-table"
			emptyMessage="Nincs adat!">
			<Column
				field="betujel"
				header="Betűjel"

				style={{height: '3.2rem'}}
				editor={(props) => <InputText
					type="text"
					className="w-100"
					value={props.value || ''}
					onChange={(e) => onEditorValueChangeForRowEditing(props, e.target.value)}
				/>
				}
			/>
			<Column
				field="darabszam"
				header="Darabszám"
				style={{wordBreak: 'break-word'}}
				editor={(props) => <InputText
					keyfilter="pint"
					className="w-100"
					value={props.rowData[props.field] || ''}
					onChange={(e) => onEditorValueChangeForRowEditing(props, e.target.value)}
				/>
				}
			/>
		</DataTable>
	</>
}

const CollectorPanel = ({bankjegy}) => {
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const messageRef = useRef(null)
	const onModalOpen = () => {
		setModalIsOpen(true)
	}
	const onModalClose = () => {
		setModalIsOpen(false)
	}

	const [editable, setEditable] = useState(false)
	const userContext = useContext(UserContext)

	const bankjegyMegjegyzesQuery = useQuery(GET_BANKJEGY_MEGJEGYZES, {
		skip: !bankjegy || !bankjegy.id, variables: {
			bankjegyId: bankjegy.id, userId: userContext.loggedInUser.id
		},
		fetchPolicy: 'network-only',
		onError(error) {
			createErrorGrowl(error, 'Nem sikerült a bankjegy megjegyzést betölteni!', userContext, 20000)
		}
	})

	const [saveBankjegyMegjegyzes] = useMutation(SAVE_BANKJEGY_MEGJEGYZES, {
		onCompleted({saveBankjegyMegjegyzes}) {
			if (saveBankjegyMegjegyzes) {
				userContext.growl({severity: 'info', summary: 'Gyűjtői megjegyzés mentése sikeresen megtörtént!'})
				setEditable(false)
			}
		},
		onError(error) {
			createErrorGrowl(error, 'Sikertelen mentés!', userContext, 20000)
		},
		refetchQueries: [{
			query: GET_BANKJEGY_MEGJEGYZES,
			variables: {bankjegyId: bankjegy.id, userId: userContext.loggedInUser.id}
		}]
	})
	const [deleteBankjegyMegjegyzes] = useMutation(DELETE_BANKJEGY_MEGJEGYZES, {
		variables: {
			bankjegyMegjegyzesTorles: {
				bankjegyId: bankjegy.id,
				felhasznaloId: userContext.loggedInUser.id
			}
		},
		onCompleted({deleteBankjegyMegjegyzes}) {
			if (deleteBankjegyMegjegyzes) {
				userContext.growl({severity: 'info', summary: 'Gyűjtői megjegyzés törlése sikeresen megtörtént!'})
				onModalClose()
			}
		},
		onError(error) {
			createErrorGrowl(error, 'Sikertelen törlés!', userContext, 20000)
		},
		refetchQueries: [{
			query: GET_BANKJEGY_MEGJEGYZES,
			variables: {bankjegyId: bankjegy.id, userId: userContext.loggedInUser.id}
		}]
	})
	const bankjegyMegjegyzes = bankjegyMegjegyzesQuery && bankjegyMegjegyzesQuery.data && bankjegyMegjegyzesQuery.data.getBankjegyMegjegyzes

	const initBankjegyMegjegyzesBetujelek = (bankjegyMegjegyzesBetujelek) => {
		let initBankjegyMegjegyzesBetujelek = []
		if (bankjegy.bankjegyBetujelek) {
			bankjegy.bankjegyBetujelek.forEach(b => {
				let filteredBetujel = bankjegyMegjegyzesBetujelek && bankjegyMegjegyzesBetujelek.filter(f => f && f.betujel === b.betujel)
				if (filteredBetujel && filteredBetujel.length > 0) {
					initBankjegyMegjegyzesBetujelek.push(filteredBetujel[0])
				} else {
					initBankjegyMegjegyzesBetujelek.push({id: null, betujel: b.betujel, darabszam: null})
				}
			})
		}
		return initBankjegyMegjegyzesBetujelek
	}

	const initialValues = {
		megjegyzes: (bankjegyMegjegyzes && bankjegyMegjegyzes.megjegyzes) || '',
		darabszamUnc: (!bankjegy.tobbUncErtek && (bankjegyMegjegyzes && bankjegyMegjegyzes.darabszamUnc)) || '',
		darabszamEf: (bankjegyMegjegyzes && bankjegyMegjegyzes.darabszamEf) || '',
		darabszamVf: (bankjegyMegjegyzes && bankjegyMegjegyzes.darabszamVf) || '',
		bankjegyMegjegyzesBetujelek: (bankjegy.tobbUncErtek && initBankjegyMegjegyzesBetujelek(bankjegyMegjegyzes && bankjegyMegjegyzes.bankjegyMegjegyzesBetujelek)) || null
	}

	/* eslint-disable no-template-curly-in-string */
	setLocale({
		mixed: {
			notType: ({path, type, value, originalValue}) => {
				return 'Nem megfelelő érték!'
			}
		},
		string: {
			max: 'A mezőnek maximum ${max} karakter hosszúnak lehet lennie!'
		},
		number: {
			min: 'A legkisebb megadható érték: ${min}!',
			max: 'A legnagyobb megadható érték: ${max}!'
		}
	})

	const collectorPanelValidationSchema = Yup.object().shape({
		megjegyzes: Yup.string().max(4000),
		darabszamUnc: Yup.number().min(0).max(99),
		darabszamEf: Yup.number().min(0).max(99),
		darabszamVf: Yup.number().min(0).max(99)
	})

	const submit = (values) => {
		if (bankjegy && bankjegy.id) {
			saveBankjegyMegjegyzes({
				variables: {
					bankjegyMegjegyzesMentes: {
						felhasznaloId: userContext.loggedInUser.id,
						bankjegyId: bankjegy.id,
						megjegyzes: values.megjegyzes || null,
						darabszamUnc: values.darabszamUnc || null,
						darabszamEf: values.darabszamEf || null,
						darabszamVf: values.darabszamVf || null,
						bankjegyMegjegyzesBetujelek: values.bankjegyMegjegyzesBetujelek && values.bankjegyMegjegyzesBetujelek.map(m => {
							return !m.darabszam ? {...m, darabszam: null} : m
						})
					}
				}
			}).then(() => {
				messageRef.current && messageRef.current.clear()
			})
		}
	}

	const uncValuesChanged = (
			bankjegy.tobbUncErtek && bankjegyMegjegyzes && (
				bankjegyMegjegyzes.darabszamUnc ||
				(bankjegy.bankjegyBetujelek && !bankjegyMegjegyzes.bankjegyMegjegyzesBetujelek) ||
				(!bankjegy.bankjegyBetujelek && bankjegyMegjegyzes.bankjegyMegjegyzesBetujelek) ||
				(bankjegy.bankjegyBetujelek && bankjegyMegjegyzes.bankjegyMegjegyzesBetujelek && bankjegyMegjegyzes.bankjegyMegjegyzesBetujelek.length !== bankjegy.bankjegyBetujelek.length) ||
				(bankjegy.bankjegyBetujelek?.map(m => m.betujel).join('') !== bankjegyMegjegyzes.bankjegyMegjegyzesBetujelek?.map(m => m.betujel).join(''))
			))
		|| (
			!bankjegy.tobbUncErtek && bankjegyMegjegyzes && bankjegyMegjegyzes.bankjegyMegjegyzesBetujelek)
	if (uncValuesChanged) {
		if (messageRef.current && messageRef.current.state?.messages.length === 0) {
			messageRef.current.show({severity: 'warn', summary: '', detail: 'Az UNC értékek megváltoztak. Kérem, ellenőrizze a gyűjtői megjegyzéseit!', closable: false, life: 120000})
		}
	} else {
		messageRef.current && messageRef.current.clear()
	}

	return <div className="m-3">
		<Dialog
			header="Törlés"
			visible={modalIsOpen}
			onHide={onModalClose}>
			<div className="">
				<div>Biztosan törölni akarja a gyűjtő megjegyzést?</div>
				<div className="buttons mt-2">
					<Button label="Igen" className="red-button" onClick={() => {
						deleteBankjegyMegjegyzes().then()
					}
					}/>
					<Button onClick={onModalClose} label="Nem" className="green-button ml-1"/>
				</div>
			</div>
		</Dialog>
		<hr/>
		<div className="">
			{!editable && <div className="row">
				<div className="col">
					<h5>Gyűjtői megjegyzéseim</h5>
				</div>
				<div className="col-auto">
					<div className="buttons">
						<button className="brown-button ml-2" title="Gyűjtő megjegyzés rögzítése" onClick={() => {
							setEditable(true)
						}}>
							<div className="d-inline-flex align-items-center">
								<img src={imgEdit216} alt="edit-megjegyzes"/>
							</div>
						</button>
						{bankjegyMegjegyzes && <button className="red-button ml-1 cursor-pointer" onClick={() => {
							onModalOpen()
						}}>
							<div className="d-inline-flex align-items-center">
								<img src={imgDelete16} alt="delete-bankjegy"/>
							</div>
						</button>}
					</div>
				</div>
			</div>}
			<Messages className="mt-3" ref={messageRef}/>
			{!bankjegyMegjegyzes && <div className="font-italic">Nem rögzített még gyűjtő megjegyzést!</div>}
			{bankjegyMegjegyzes && !editable && <div className="row mt-2 ps-2">
				<div className="col-12 col-sm-4 col-md-12 col-lg-4">
					{bankjegy.tobbUncErtek ?
						<div className="row">
							{(bankjegyMegjegyzes.bankjegyMegjegyzesBetujelek ?
								[...bankjegyMegjegyzes.bankjegyMegjegyzesBetujelek] :
								[...(bankjegy.bankjegyBetujelek ? bankjegy.bankjegyBetujelek : [])])
								.map((b, idx) => {
									return <div key={`${b.betujel}${idx}`} className="col-12">
										<LabelValue label={`UNC darabszám (${b.betujel})`} value={b.darabszam}></LabelValue>
									</div>
								})}
						</div> :
						<LabelValue label="UNC darabszám" value={bankjegyMegjegyzes.darabszamUnc}></LabelValue>
					}
				</div>
				<div className="col-12 col-sm-4 col-md-12 col-lg-4">
					<LabelValue label="EF darabszám" value={bankjegyMegjegyzes.darabszamEf}></LabelValue>
				</div>
				<div className="col-12 col-sm-4 col-md-12 col-lg-4">
					<LabelValue label="VF darabszám" value={bankjegyMegjegyzes.darabszamVf}></LabelValue>
				</div>
				<div className="col-12">
					<LabelValue label="Megjegyzés" value={bankjegyMegjegyzes.megjegyzes}></LabelValue>
				</div>
			</div>}
			{editable && <Formik
				initialValues={initialValues}
				validationSchema={collectorPanelValidationSchema}
				onSubmit={submit}
			>
				{({handleSubmit, values}) => (
					<form className="search-form"
						onSubmit={handleSubmit}
					>
						<h5 className="mb-3">Gyűjtői megjegyzéseim</h5>
						<div className="row">
							<div className="col-12 col-sm-4 col-md-12 col-lg-4">
								<div className="row mb-3">
									{bankjegy.tobbUncErtek ?
										<div className="col">
											<UNCPieceEditor fieldName="bankjegyMegjegyzesBetujelek"/>
										</div> :
										<div className="col-6 col-sm-12 col-md-6 col-lg-12 col-xl-6">
											<NumberEditor focus readonly={!editable} fieldLabel="UNC darabszám" fieldName="darabszamUnc" inputGroupAfter="db"/>
										</div>}
								</div>
							</div>
							<div className="col-12 col-sm-4 col-md-12 col-lg-4">
								<div className="row mb-3">
									<div className="col-6 col-sm-12 col-md-6 col-lg-12 col-xl-6">
										<NumberEditor readonly={!editable} fieldLabel="EF darabszám" fieldName="darabszamEf" inputGroupAfter="db"/>
									</div>
								</div>
							</div>
							<div className="col-12 col-sm-4 col-md-12 col-lg-4">
								<div className="row mb-3">
									<div className="col-6 col-sm-12 col-md-6 col-lg-12 col-xl-6">
										<NumberEditor readonly={!editable} fieldLabel="VF darabszám" fieldName="darabszamVf" inputGroupAfter="db"/>
									</div>
								</div>
							</div>
							<div className="col-12">
								<div className="form-row mb-3">
									<div className="col-12">
										<TextAreaEditor rows={5} readonly={!editable} fieldLabel="Megjegyzés" fieldName="megjegyzes"/>
									</div>
								</div>
							</div>
						</div>
						{editable && <div className="buttons">
							<Button
								disabled={isFormEmpty(values) && !uncValuesChanged}
								className="brown-button"
								label="Mentés"
								type="submit"/>
							<Button
								className="green-button"
								type="button"
								onClick={() => {
									setEditable(false)
								}}
								label="Mégsem"
							/>
						</div>}
					</form>
				)}
			</Formik>}
		</div>
	</div>
}

export default CollectorPanel
