import {ErrorMessage, useField} from 'formik'
import React from 'react'
import {InputTextarea} from "primereact/inputtextarea";

const TextAreaEditor = ({
						fieldName,
						fieldLabel,
						className,
						labelClassName,
						placeholder,
						disabled = false,
						children,
						readonly=false,
						rows,
						cols,
						required,
						maxLength
					}) => {
	const [field, meta] = useField(fieldName);
	return (
		<div className={`form-group w-100 ${className ? className : ''}`}>
			{fieldLabel ? <label className={`${(!readonly && required) ? 'required' : ''} ${readonly ? 'col-form-label' : ''} ${labelClassName ? labelClassName : ''}`} htmlFor={'input' + field.name}>{fieldLabel}</label> : ''}
			{readonly ?
				<input type="text" readOnly className="form-control-plaintext" id={'input' + field.name} value={field.value}/> :
				<>
					<InputTextarea
						className={`${className ? className : ''} form-control ${meta.error && meta.touched ? 'is-invalid' : ''}`}
						id={'input' + field.name}
						name={field.name}
						placeholder={`${placeholder || fieldLabel}${required ? ' *' : ''}`}
						value={field.value}
						onChange={field.onChange}
						onBlur={field.onBlur}
						disabled={disabled}
						rows={rows}
						cols={cols}
						required={required}
						maxLength={maxLength}
					/>

					<ErrorMessage name={field.name} component="div" className="error-message"/>
				</>
			}
			{children}
		</div>
	)}

export default TextAreaEditor
