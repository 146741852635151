import React, {useContext, useState} from 'react'
import gql from 'graphql-tag'
import {useQuery} from '@apollo/client'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {UserContext} from '../../components/UserContext'
import {Button} from 'primereact/button'
import {useHistory} from 'react-router'
import AdminPanel from '../../layout/AdminPanel'
import {formatCurrency, formatDate} from '../Common'
import {ProgressBar} from 'primereact/progressbar'

const FIND_ALL_BANKJEGY = gql`
    query FindAllBankjegy {
        findAllBankjegy {
            sorrend
            forrasAzonosito
            penznem
            sorozat {
                nev
            }
            tipus
            meret
            keltezesDatuma
            keltezesEve
            forgalomban
            kibocsajtasDatuma
            kibocsajtasEve
            bevonasDatuma
            bevonasEve
            atvaltasDatuma
            atvaltasEve
            nevertek
            nevertekEgyseg
            ertekEf
            ertekVf
            ertekUnc
            tobbUncErtek
            bankjegyBetujelek {
                betujel
                ertek
            }
            megjegyzes
            bankjegyMegjegyzes {
                megjegyzes
                darabszamEf
                darabszamVf
                darabszamUnc
                bankjegyMegjegyzesBetujelek {
                    betujel
                    darabszam
                }
            }
            betoltesDatuma
            letrehozasIdeje
            statusz
        }
    }
`

const GyujtemenyemPage = () => {
	const history = useHistory()
	const [values, setValues] = useState([])
	const findAllBankjegyQuery = useQuery(FIND_ALL_BANKJEGY, {
		fetchPolicy: 'network-only',
		onCompleted: (data) => {
			if (data?.findAllBankjegy) {
				const initValues = []
				data.findAllBankjegy.forEach(b => {
					const getUncErtek = (bankjegy) => {
						if (!b.tobbUncErtek) {
							return {darabszam: b.bankjegyMegjegyzes?.darabszamUnc, ertek: b.ertekUnc}
						}
						if (b.bankjegyMegjegyzes?.bankjegyMegjegyzesBetujelek) {
							const betujelek = b.bankjegyMegjegyzes.bankjegyMegjegyzesBetujelek.filter(f => f.darabszam)
							if (betujelek?.length > 0) {
								return betujelek.map(bmb => {
									let ertek = b.bankjegyBetujelek.find(bb => bb.betujel === bmb.betujel)?.ertek
									return {betujel: bmb.betujel, darabszam: bmb.darabszam, ertek: ertek}
								}).sort((a, b) => {
									return a.betujel.localeCompare(b.betujel)
								})
							}
						}
					}

					let bankjegyValue = {
						sorrend: b.sorrend,
						sorozat: b.sorozat?.nev,
						tipus: b.tipus,
						meret: b.meret ? (b.meret + ' mm') : '',
						keltezes: b.keltezesDatuma ? formatDate(b.keltezesDatuma) : b.keltezesEve,
						forgalomban: b.forgalomban ? 'igen' : 'nem',
						kibocsajtas: b.kibocsajtasDatuma ? formatDate(b.kibocsajtasDatuma) : b.kibocsajtasEve,
						bevonas: b.bevonasDatuma ? formatDate(b.bevonasDatuma) : b.bevonasEve,
						atvaltas: b.atvaltasDatuma ? formatDate(b.atvaltasDatuma) : b.atvaltasEve,
						nevertek: formatCurrency(b.nevertek, b.nevertekEgyseg, b.penznem),
						megjegyzes: b.megjegyzes,
						bankjegyMegjegyzes: b.bankjegyMegjegyzes?.megjegyzes
					}
					let uncErtek = getUncErtek(b)
					let uncErtekArray = []
					if (Array.isArray(uncErtek)) {
						uncErtekArray = [...uncErtek] //ha tömb akkor betűjeles
						uncErtek = {} //ekkor a sima UNC erteket törölni kell
					}

					initValues.push({
						...bankjegyValue,
						darabszamEf: b.bankjegyMegjegyzes?.darabszamEf && `${b.bankjegyMegjegyzes?.darabszamEf} db`,
						ertekEf: formatCurrency(b.ertekEf),
						darabszamVf: b.bankjegyMegjegyzes?.darabszamVf && `${b.bankjegyMegjegyzes?.darabszamVf} db`,
						ertekVf: formatCurrency(b.ertekVf),
						darabszamUnc: uncErtek.darabszam && `${uncErtek.darabszam} db`,
						ertekUnc: formatCurrency(uncErtek.ertek)
					})

					uncErtekArray.forEach(uncData => {
						initValues.push({
							...bankjegyValue,
							betujelUnc: uncData.betujel,
							darabszamUnc: uncData.darabszam && `${uncData.darabszam} db`,
							ertekUnc: formatCurrency(uncData.ertek)
						})
					})
				})
				setValues(initValues)
			}
		}
	})

	const saveAsExcelFile = (buffer, fileName) => {
		import('file-saver').then(module => {
			if (module && module.default) {
				let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
				let EXCEL_EXTENSION = '.xlsx'
				const data = new Blob([buffer], {
					type: EXCEL_TYPE
				})

				module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION)
			}
		})
	}
	const exportExcel = () => {
		import('xlsx').then(xlsx => {
			const worksheet = xlsx.utils.json_to_sheet(values)
			const workbook = {Sheets: {'data': worksheet}, SheetNames: ['data']}
			const excelBuffer = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'})
			saveAsExcelFile(excelBuffer, 'gyujtemeny')
		})
	}

	const userContext = useContext(UserContext)
	return <AdminPanel className="gyujtemenyem-content" stretch label="Gyűjteményem">
		<div>
			{userContext.loggedInUser.szerep !== 'ADMIN' || !userContext.loggedInUser.superuser ?
				<div className="alert alert-warning" role="alert">Nincs megfelelő jogosultsága!</div> :
				<>
					{findAllBankjegyQuery.loading && <ProgressBar className="my-3" mode="indeterminate"/>}
					<div className="d-flex justify-content-end mb-2">
						<Button
							type="button"
							icon="pi pi-file-excel"
							onClick={exportExcel}
							className="p-button-success mr-2"
							data-pr-tooltip="XLS"
						/>
					</div>
					<DataTable
						autoLayout={true}
						className="w-100 overflow-auto"
						stripedRows
						value={values}
						emptyMessage="Nincs adat!"
					>
						<Column sortable field="sorrend" header="Sorrend"/>
						<Column sortable field="sorozat" header="Sorozat"/>
						<Column sortable field="tipus" header="Típus"/>
						<Column sortable field="meret" header="Méret"/>
						<Column sortable field="forgalomban" header="Forgalomban"/>
						<Column sortable field="keltezes" header="Keltezés dátuma"/>
						<Column sortable field="kibocsajtas" header="Kibocsájtás dátuma"/>
						<Column sortable field="bevonas" header="Bevonás dátuma"/>
						<Column sortable field="atvaltas" header="Átváltás dátuma"/>
						<Column sortable field="nevertek" header="Névérték"/>
						<Column sortable field="ertekEfDb" header="Darabszam EF" body={row => <div className="text-nowrap">{row.darabszamEf}</div>}/>
						<Column sortable field="ertekEf" header="Érték EF" body={row => <div className="text-nowrap">{row.ertekEf}</div>}/>
						<Column sortable field="ertekVfDb" header="Darabszam VF" body={row => <div className="text-nowrap">{row.darabszamVf}</div>}/>
						<Column sortable field="ertekVf" header="Érték VF" body={row => <div className="text-nowrap">{row.ertekVf}</div>}/>
						<Column sortable field="ertekUnc" header="Betűjel UNC" body={row => <div className="text-nowrap">{row.betujelUnc}</div>}/>
						<Column sortable field="ertekUnc" header="Darabszam UNC" body={row => <div className="text-nowrap">{row.darabszamUnc}</div>}/>
						<Column sortable field="ertekUnc" header="Érték UNC" body={row => <div className="text-nowrap">{row.ertekUnc}</div>}/>
						<Column sortable field="megjegyzes" header="Megjegyzés"/>
						<Column sortable field="bankjegyMegjegyzes" header="Bankjegy megjegyzés"/>
					</DataTable>
				</>
			}</div>
		<div className="buttons mt-3">
			<Button
				onClick={() => {
					history.push('/')
				}}
				className="brown-button ml-1"
				label="Bezár"
				type="button"/>
		</div>
	</AdminPanel>
}

export default GyujtemenyemPage
