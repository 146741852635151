import {InputText} from 'primereact/inputtext'
import {ErrorMessage, useField} from 'formik'
import React, {useEffect} from 'react'
import $ from "jquery"

export const InputGroupWrapper = ({children, inputGroupBefore, inputGroupAfter,}) => {
	return (
		<>
			{(inputGroupBefore || inputGroupAfter) ?
				<div className="p-inputgroup">
					{inputGroupBefore ? <span className="p-inputgroup-addon">{inputGroupBefore}</span> : ''}
					{children}
					{inputGroupAfter ? <span className="p-inputgroup-addon">{inputGroupAfter}</span> : ''}
				</div> :
				children}
		</>
	)
}


const TextEditor = ({
	fieldName,
	fieldLabel,
	className,
	labelClassName,
	placeholder,
	disabled = false,
	children,
	inputGroupBefore,
	inputGroupAfter,
	focus=false,
	readonly=false,
	keyfilter,
	maxLength,
	required=false
	}) => {
	const [field, meta] = useField(fieldName);
	useEffect(()=>{
		if (focus)
		{
			$('#input'+field.name).focus()
		}
	}, [field.name, focus])
	return (
		<div className={`form-group w-100 ${className ? className : ''}`}>
			{fieldLabel ? <label className={`${!readonly && required ? 'required' : ''} ${readonly ? 'col-form-label' : ''} ${labelClassName ? labelClassName : ''}`} htmlFor={'input' + field.name}>{fieldLabel}</label> : ''}
			{readonly ?
				<input type="text" readOnly className="form-control-plaintext" id={'input' + field.name} value={field.value}/> :
				<>
					<InputGroupWrapper inputGroupAfter={inputGroupAfter} inputGroupBefore={inputGroupBefore}>
						<InputText
							keyfilter={keyfilter}
							className={`${className ? className : ''} form-control ${meta.error && meta.touched ? 'is-invalid' : ''}`}
							id={'input' + field.name}
							name={field.name}
							placeholder={`${placeholder || fieldLabel}${(!placeholder && required) ? ' *' : ''}`}
							value={field.value}
							onChange={field.onChange}
							onBlur={field.onBlur}
							disabled={disabled}
							maxLength={maxLength}
						/>
					</InputGroupWrapper>
					<ErrorMessage name={field.name} component="div" className="error-message"/>
				</>
			}
			{children}
		</div>
)}

export default TextEditor
