import React from 'react'
import ReactDOM from 'react-dom'
import './styles/main.scss'
import '@popperjs/core'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import 'primereact/resources/themes/nova/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import * as serviceWorker from './serviceWorker'
import {ApolloProvider} from '@apollo/client'
import {ApolloClient} from '@apollo/client/core'
import {BatchHttpLink} from '@apollo/client/link/batch-http'
import {InMemoryCache} from '@apollo/client/cache'
import {BankjegyContextProvider} from './components/BankjegyContext'
import {createUploadLink} from 'apollo-upload-client'
import BankjegyApp from './BankjegyApp'

const httpLink = createUploadLink(new BatchHttpLink({uri: '/graphql'}))
/*
const authLink = new ApolloLink((operation, forward) => {
	// CSRF token kiolvasása
	// eslint-disable-next-line
	const token = document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*\=\s*([^;]*).*$)|^.*$/, '$1')

	// CSRF token hozzáadása a request paramétereihez
	operation.setContext({
		headers: {
			'X-XSRF-TOKEN': token
		}
	})

	// Call the next link in the middleware chain.
	return forward(operation)
})

const linkWithAuth = authLink.concat(httpLink)
*/
setInterval(() => {
	fetch('/extend_session')
		.then(result => result.json())
		.then(userLoggedIn => {
			if (userLoggedIn) {
				console.info('User logged in.')
			}
		})
}, 2 * 60 * 1000)

let lastTime = (new Date()).getTime()
setInterval(function () {
	const currentTime = (new Date()).getTime()
	if (currentTime > (lastTime + 1000 * 60)) {
		fetch('/extend_session')
			.then(result => result.json())
			.then(userLoggedIn => {
				if (!userLoggedIn) {
					window.location.href = '/'
				}
			})
	}
	lastTime = currentTime
}, 2000)

const client = new ApolloClient({
	//link: linkWithAuth,
	link: httpLink,
	cache: new InMemoryCache({
		addTypename:false
	})
})

ReactDOM.render(
	<ApolloProvider client={client}>
		<BankjegyContextProvider>
			<BankjegyApp/>
		</BankjegyContextProvider>
	</ApolloProvider>,
	document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
