import {Dropdown} from 'primereact/dropdown'
import {ErrorMessage, useField} from 'formik'
import React from 'react'

const ComboEditor = ({
	fieldName,
	fieldLabel,
	placeholder,
	enumClass,
	except,
	emptyValue,
	onChange,
	disabled = false,
	readonly = false,
	possibleValues,
	required,
	dataKey,
	emptyMessage,
	editable = false
}) => {
	const [field, meta] = useField(fieldName);
	const onComboChange = (e) => {
		if (onChange)
		{
			onChange(e.value)
		}
		field.onChange({target: { value: e.value, name: fieldName}})
 	}
	return (
		<div className=''>
  			<div className='form-group w-100'>
				{fieldLabel ? <label className={`${!readonly && required ? 'required' : ''} ${readonly ? 'col-form-label' : ''}`} htmlFor={'input' + field.name}>{fieldLabel}</label> : ''}
	  			{readonly ?
		  			<input type="text" readOnly className="form-control-plaintext" id={'input' + field.name} value={field.value}/> :
		  			<>
			  			<Dropdown
							className={`form-control ${meta.error && meta.touched ? 'is-invalid' : ''}`}
							id={'input' + field.name}
							placeholder={`${placeholder || fieldLabel}${required ? ' *' : ''}`}
							value={field.value}
							onChange={onComboChange}
							options={possibleValues}
							disabled={disabled}
							required={required}
							emptyMessage={emptyMessage}
							editable={editable}
					  	/>
			  			<ErrorMessage name={field.name} component="div" className="error-message"/>
					</>
				}
  			</div>
		</div>
	)
}

export default ComboEditor
