import AdminPanel from '../../layout/AdminPanel'
import React, {useContext, useEffect, useState} from 'react'
import {UserContext} from '../../components/UserContext'
import gql from 'graphql-tag'
import {useQuery} from '@apollo/client'
import {Chart} from 'primereact/chart'
import {Form, Formik} from 'formik'
import ComboEditor from '../../components/editors/ComboEditor'
import {Button} from 'primereact/button'
import {getEnumValue} from '../EnumUtils'
import {LIST_SOROZAT} from '../../layout/Menu'
import DateEditor from '../../components/editors/DateEditor'
import {formatDateForSubmit, formatNumber} from '../Common'
import * as Yup from 'yup'
import {ProgressBar} from 'primereact/progressbar'

export const LIST_ARVALTOZAS = gql`
    query ListArvaltozas($penznem: Penznem, $sorozatId: Long, $bankjegyId: Long, $startDate: Date, $minoseg: Minoseg) {
        listArvaltozas(penznem: $penznem, sorozatId: $sorozatId, bankjegyId: $bankjegyId, startDate: $startDate, minoseg: $minoseg) {
            name
            arvaltozasChartData {
                labels
                datasets {
                    label
                    data
                    borderColor:color
                }
            }
        }
    }
`

export const ArvaltozasChart = ({filter}) => {
	const [data, setData] = useState([])
	let [loading, setLoading] = useState(false)
	const [chartOptions, setChartOptions] = useState({})

	const queryListArvaltozas = useQuery(LIST_ARVALTOZAS, {skip: true, fetchPolicy: 'network-only'})

	useEffect(() => {
		const documentStyle = getComputedStyle(document.documentElement)

		const options = {
			series: [{
				name: 'Adatok',
			}],
			lines: {
				fill: false,
				strokeWidth: 2,
				stroke: '#007bff'
			},
			scales: {
				y:{
					ticks: {
						callback: (value, index, ticks) => {
							let baseValue = ticks.find(tick => tick.value !== 0)?.value;
							if (baseValue === undefined) {
								baseValue = 0;
							}
							return `${formatNumber(value)}`;
						}
					}
				}
			}
		}

		setChartOptions(options)
	}, [])
	useEffect(() => {
			if (filter?.penznem || filter?.bankjegyId) {
				setLoading(true)
				queryListArvaltozas.refetch({
					penznem: filter.penznem,
					sorozatId: filter.sorozat,
					bankjegyId: filter.bankjegyId,
					startDate: filter.kezdoDatum ? formatDateForSubmit(filter.kezdoDatum) : null
				}).then(({data})=> {
					if (data?.listArvaltozas) {
						setData(data.listArvaltozas)
					}
					setLoading(false)
				}).finally(()=>{
					setLoading(false)
				})
			}
	}, [filter])
	return <>
		{loading && <ProgressBar className="mt-3" mode="indeterminate"/>}
		{!loading && data.map((arvaltozasChartOption, idx) => {
			return <div key={`chart_${idx}`} >
				<h4 className="mt-3 text-center">{arvaltozasChartOption.name}</h4>
				<div className='d-flex justify-content-center'>
					<Chart
						className="mb-5"
						style={{width:'60%'}}
						type="line"
						data={Object.assign({}, arvaltozasChartOption.arvaltozasChartData)}
						options={chartOptions}
					/>
				</div>
			</div>
		})}</>
}

const ArvaltozasOsszesitesPage = () => {
	const userContext = useContext(UserContext)
	let [filter, setFilter] = useState(null)
	let [possibleSorozat, setPossibleSorozat] = useState([])
	let [possiblePenznem, setPossiblePenznem] = useState([])


	useEffect(() => {
		let res = [{label: 'Válasszon!', value: null}]
		userContext.possiblePenznem.forEach((value, index) => (
			res = [...res, {label: getEnumValue('Penznem', value), value: value}]
		))
		setPossiblePenznem(res)

	}, [userContext.possiblePenznem])

	const listSorozatQuery = useQuery(LIST_SOROZAT, {
		skip: true,
	})

	const initialValues = {
		penznem: null,
		sorozatId: null,
		kezdoDatum:null
	}

	const submit = (values) => {
		setFilter(values)
	}
	return <AdminPanel
		className="arvaltozas-osszesites-content"
		stretch
		label="Bankjegy árváltozás statisztika"
	>
		<div>
			{userContext.loggedInUser.szerep !== 'ADMIN' || !userContext.loggedInUser.superuser ?
				<div
					className="alert alert-warning"
					role="alert"
				>Nincs megfelelő jogosultsága!</div> :
				<>
					<Formik

						initialValues={initialValues}
						onSubmit={submit}
						validationSchema={Yup.object({
						penznem: Yup.string().nullable().required('A mező kitöltése kötelező')
					})}
					>
						{({setFieldValue, values, errors, isSubmitting}) => (
							<Form className='mb-5'>
								<div className="row">
									<div className="col-12 col-sm-6 mb-3">
										<ComboEditor
											fieldLabel="Pénznem"
											fieldName="penznem"
											possibleValues={possiblePenznem}
											onChange={(value) => {
												setFieldValue('sorozat', null)
												setPossibleSorozat([])
												if (value)
												{
													listSorozatQuery?.refetch({penznem: value})?.then((({data})=>{
														const sorozatList = data.listSorozatByPenznem
														let res = [{label: 'Válasszon!', value: null}]
														sorozatList.forEach((value, index) => (
															res = [...res, {label: value.nev, value: value.id}]
														))
														setPossibleSorozat(res)
													}))
												}
											}}
										/>
									</div>
									<div className="col-12 col-sm-6 mb-3">
										<ComboEditor
											fieldLabel="Sorozat"
											fieldName="sorozat"
											possibleValues={possibleSorozat}
											emptyMessage='Először válassza ki a pénznemet!'
										/>
									</div>
									<div className="col-12 col-sm-6 mb-3">
										<DateEditor
											fieldLabel="Kezdő dátum"
											fieldName="kezdoDatum"
											showButtonBar
										/>
									</div>
								</div>
								<div className="buttons">
									<Button
										className="brown-button"
										label="Keresés"
										type="submit"
									/>
								</div>
							</Form>
						)}
					</Formik>
					<ArvaltozasChart filter={filter}/>
				</>
			}</div>
	</AdminPanel>
}

export default ArvaltozasOsszesitesPage