import {useEffect, useState} from 'react'

const getViewport = () => {
	const width = Math.max(
		document.documentElement.clientWidth,
		window.innerWidth || 0
	)

	if (width <= 576) return 'xs'
	if (width <= 768) return 'sm'
	if (width <= 992) return 'md'
	if (width <= 1200) return 'lg'
	return 'xl'
}

const useViewport = () => {
	const [viewport, setViewport] = useState(getViewport())

	useEffect(() => {
		let setFromEvent = (e) =>{
			let currentViewport = getViewport()
			if (viewport !== currentViewport)
			{
				setViewport(currentViewport)
			}
		}
		window.addEventListener("resize", setFromEvent)
		return () => {
			window.removeEventListener("resize", setFromEvent);
		};
	}, [viewport]);

	return viewport;
}

export default useViewport