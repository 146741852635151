import React, {useContext} from 'react'
import gql from 'graphql-tag'
import {useQuery} from '@apollo/client'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {UserContext} from '../../components/UserContext'
import {Button} from 'primereact/button'
import {useHistory} from 'react-router'
import AdminPanel from '../../layout/AdminPanel'

const LIST_BANKJEGY_MEGJEGYZES_OSSZESITES = gql`
    query ListBankjegyMegjegyzesOsszesites {
        listBankjegyMegjegyzesOsszesites {
            megjegyzesSzam
            felhasznalo {
                nev
                email
            }
        }
    }
`

const MegjegyzesOsszesitesPage = () => {
	const history = useHistory()
	const queryListMegjegyzes = useQuery(LIST_BANKJEGY_MEGJEGYZES_OSSZESITES)
	const userContext = useContext(UserContext)
	return <AdminPanel className="megjegyzes-osszesites-content" stretch label="Bankjegy megjegyzés statisztika">
		<div>
			{userContext.loggedInUser.szerep !== 'ADMIN' || !userContext.loggedInUser.superuser ?
				<div className="alert alert-warning" role="alert">Nincs megfelelő jogosultsága!</div> :
				<DataTable autoLayout={true} className="w-100" stripedRows
					value={queryListMegjegyzes.data ? queryListMegjegyzes.data.listBankjegyMegjegyzesOsszesites : []}>
					<Column sortable field="felhasznalo.nev" header="Név"/>
					<Column sortable field="felhasznalo.email" header="E-mail"/>
					<Column sortable field="megjegyzesSzam" header="Megjegyzések száma"/>
				</DataTable>
			}</div>
		<div className="buttons mt-3">
			<Button
				onClick={() => {
					history.push('/')
				}}
				className="brown-button ml-1"
				label="Bezár"
				type="button"/>
		</div>
	</AdminPanel>
}

export default MegjegyzesOsszesitesPage
