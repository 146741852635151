import React, {useContext} from 'react'
import mailText from "../../images/email_impress.png";
import mailTextDo from "../../images/email_impress_do.png";
import mailTextBankjegyPortal from "../../images/email_bankjegyportal.png";
import {Logo} from "../../layout/Menu";
import UserHeaderPanel from "../user/UserHeaderPanel";
import {Link} from "react-router-dom";
import {UserContext} from "../../components/UserContext";

export const CKLabelValue = ({ label, value, rendered = true, className }) => {
	return <React.Fragment>
		{rendered && <label className={`col-sm-5 col-form-label ${className || ''}`}>{label}:</label>}
		{rendered && <div className={`col-sm-7 form-control-plaintext ${className || ''}`}>{value}</div>}
	</React.Fragment>
}

const ImpressPage = () => {
	const logo = <Logo/>
	const userContext = useContext(UserContext);
	return (
		<div className="impress-page-content main-page-content mb-3">
			<div className="d-flex h-100 flex-column">
				<UserHeaderPanel theme="black" logo={logo}/>
				<div className="container-fluid mt-5 col-12 col-sm-10 col-lg-8 col-xl-6">
					<div className="d-flex flex-column align-self-center w-100 py-3">
						<div className="col">
							<h1 className="mb-4">Impresszum</h1>
						</div>
						<div className="col">
							<div className='row'>
								<CKLabelValue label="Név" value='Scriptum Informatika Zrt.'/>
								<CKLabelValue label="Székhely" value='6771 Szeged, Mályva utca 34.'/>
								<CKLabelValue label="Központi telefonszám" value='+36 62 406 133'/>
								<CKLabelValue label="Központi e-mail adatkezelési kérdés esetén" value={<img src={mailText} alt='mail-text'/>}/>
								<CKLabelValue label="Központi e-mail tartalmi kérdés esetén" value={<img src={mailTextBankjegyPortal} alt='mail-text'/>}/>
								<CKLabelValue label="A céget nyilvántartó hatóság" value='Szegedi Törvényszék Cégbírósága'/>
								<CKLabelValue label="Cégjegyzékszám" value='06-10-000238'/>
								<CKLabelValue label="Adószám" value='11596202-2-06'/>
								<CKLabelValue label="Tárhelyszolgáltató" value='DigitalOcean, LLC'/>
								<CKLabelValue label="Tárhelyszolgáltató e-mail címe" value={<img src={mailTextDo} alt='mail-text-digital-ocean'/>}/>
							</div>
						</div>
						{!userContext.loggedInUser &&
							<div className="col">
								<div className='row mb-2 mt-3'>
									<div className='col-12 col-md-12 cursor-pointer text-center'>
										<Link to='/bejelentkezes'>Vissza</Link>
									</div>
								</div>
							</div>
						}
					</div>
				</div>
			</div>
		</div>
	)
}

export default ImpressPage
