import React, {useContext, useRef} from 'react'
import {SuccessMessagePanel} from './RegistrationPage'
import {Formik} from 'formik'
import {Button} from 'primereact/button'
import gql from 'graphql-tag'
import {useMutation} from '@apollo/client'
import {ProgressBar} from 'primereact/progressbar'
import {createErrorMessage} from './LoginPage'
import {Messages} from 'primereact/messages'
import TextEditor from '../../components/editors/TextEditor'
import {UserContext} from '../../components/UserContext'
import {Logo} from '../../layout/Menu'
import {Link, Redirect, useLocation} from 'react-router-dom'
import {Panel} from 'primereact/panel'
import * as Yup from 'yup'
import {setLocale} from 'yup'

const FORGOTTEN_PASSWORD_REQUEST = gql`
    mutation ForgottenPasswordRequest($elfelejtettJelszoKeres: ElfelejtettJelszoKeres!) {
        forgottenPasswordRequest(elfelejtettJelszoKeres: $elfelejtettJelszoKeres)
    }
`

const SuccessForgottenPasswordRequest = () => {
	sessionStorage.setItem('pageCodeKey', 'loginPanel')
	return (
		<>
			<SuccessMessagePanel
				title="Jelszó módosítási igényét rögzítettük!"
				desc="Hamarosan kapni fog egy emailt a megadott email címre. Az abban található linkre kattintva tudja megmódosítani a jelszavát!"
			/>
			<div className="mt-3 mb-2 text-center">
				<Link to="/">Vissza a nyilólapra</Link>
			</div>
		</>
	)
}

const ForgottenPasswordRequestForm = () => {
	const location = useLocation()
	const messageRef = useRef(null)
	const email = (location && location.state) ? location.state.email : ''
	const [forgottenPasswordRequest, {data, loading: mutationLoading, error: mutationError}] = useMutation(FORGOTTEN_PASSWORD_REQUEST)

	/* eslint-disable no-template-curly-in-string */
	setLocale({
		mixed: {
			required: 'A mező kitöltése kötelező!'
		},
		string: {
			matches: 'Nem megfelelő ${label}! pl.: nev@email.hu'
		}
	})

	const forgottenPasswordValidationSchema = Yup.object().shape({
		email: Yup.string().max(255).required().matches(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i).required().label('email cím')
	})

	return (
		(data && data.forgottenPasswordRequest === 'SUCCESS') ?
			<SuccessForgottenPasswordRequest/> :
			<>
				<Formik
					validateOnBlur={false}
					initialValues={{email: email, password: ''}}
					validationSchema={forgottenPasswordValidationSchema}
					onSubmit={(values, {setSubmitting}) => {
						forgottenPasswordRequest({
							variables: {
								'elfelejtettJelszoKeres': {
									'email': values.email
								}
							}
						}).then()
					}}
				>
					{({handleSubmit}) => (
						<form className="form-signin"
							onSubmit={handleSubmit}
						>
							<div className="mb-3 ">
								Kérem, adja meg az e-mail címet amihez új jelszót szeretne ígényelni!
							</div>
							<div className="mb-3">
								<TextEditor focus fieldName="email" placeholder="e-mail cím"/>
							</div>

							<div>
								<Button
									className=""
									label="Jelszó módosítás kérése"
									type="submit"/>
							</div>
						</form>
					)}
				</Formik>
				{mutationLoading && <ProgressBar className="mt-3" mode="indeterminate"/>}
				{createErrorMessage(mutationError, 'Sikertelen jelszó módosítás!', messageRef, 20000)}
				<Messages className="mb-3 error-message" ref={messageRef}/>
				<div className="mb-2 cursor-pointer text-center">
					<Link to="/bejelentkezes">Bejelentkezés</Link>
				</div>
			</>
	)
}

const ForgottenPasswordRequestPage = () => {
	const userContext = useContext(UserContext)
	return (
		<>
			{userContext.loggedInUser ? <Redirect to="/"/> :
				<div className="main-page-content forgotten-password-panel">
					<div className="d-flex h-100 justify-content-center flex-column">
						<div className="logo-wrapper d-flex justify-content-center">
							<div className="d-flex flex-column align-self-center">
								<div className="col"><Logo/></div>
								<div className="col mt-5">
									<div>
										<Panel>
											<ForgottenPasswordRequestForm/>
										</Panel>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			}
		</>
	)
}

export default ForgottenPasswordRequestPage
