import React, {useEffect, useState} from 'react'
import {Logo} from "../layout/Menu";
import {Panel} from "primereact/panel";
import imgWarning from "../images/warning-128.png"
import {Redirect} from "react-router";
import UserHeaderPanel from "./user/UserHeaderPanel";
import useViewport from "./useViewport";

export const CreateInterval = ({countDown, setCountDown, timeout}) => {
    const [redirect, setRedirect] = useState(false)
    useEffect(()=>{
        let id = setInterval((e)=>{
            setCountDown(countDown-1)
            if (countDown === 0)
            {
                setRedirect(true)
            }
        }, 1000, timeout/1000)
        return () => clearInterval(id)
    })
    return (
        redirect && <Redirect to="/" />
    )
}

const ErrorPage = ({ children, ...rest }) => {
    const timeout = 8000
    const [countDown, setCountDown] = useState(timeout/1000);
    const logo = <Logo/>
    const viewport = useViewport()
  return (
      <div className="main-page-content error-page">
        <div className="d-flex h-100 justify-content-center flex-column">
          {['md', 'lg', 'xl'].includes(viewport) && <UserHeaderPanel theme="black" logo={logo}/>}
          <div className="logo-wrapper d-flex justify-content-center">
            <div className="d-flex flex-column align-self-center">
              <div className="col"><Logo/></div>
              <div className="col mt-4">
                  <Panel>
                      <div className="d-flex align-items-center">
                          <div className="col-auto p-0"><img src={imgWarning} alt="warning"/></div>
                          <div className="col p-0 px-3">
                              <span className="font-weight-bold">
                                Az oldal nem található!
                              </span><br/>
                              Az oldal {countDown} másodperc múlva át lesz írányítva a kezdő oldalra.
                          </div>
                         <CreateInterval countDown={countDown} setCountDown={setCountDown} timeout={timeout}/>
                      </div>
                  </Panel>
              </div>
            </div>
          </div>
        </div>
      </div>


  )
}
export default ErrorPage
