import {ErrorMessage, useField} from 'formik'
import React, {useState} from 'react'
import {Calendar} from 'primereact/calendar'
import {ToggleButton} from 'primereact/togglebutton'
import {InputText} from 'primereact/inputtext'
import moment from 'moment'
import {addLocale} from 'primereact/api'
import {CalendarLocale} from '../../routes/Common'

// graphql-nek  moment(values.date).format() konvertálással kell átadni
// monthNavigator: hónap választó a dropdown-ban
// yearNavigator: év választó a dropdown-ban, kötelező hozzá a yearRange megadása
// yearRange: yearNavigatorral együtt használatos: pl: '2010:2030' formátumban

// const InputGroupWrapper = ({children, inputGroupBefore, inputGroupAfter,}) => {
// 	return (
// 		<>
// 			{(inputGroupBefore || inputGroupAfter) ?
// 				<div className="p-inputgroup">
// 					{inputGroupBefore ? <span className="p-inputgroup-addon">{inputGroupBefore}</span> : ''}
// 					{children}
// 					{inputGroupAfter ? <span className="p-inputgroup-addon">{inputGroupAfter}</span> : ''}
// 				</div> :
// 				children}
// 		</>
// 	)
// }

const DateYearEditor = ({
						fieldName,
						fieldYearName,
						fieldTypeName,
						fieldLabel,
						dateLabel,
						yearLabel,
						placeholder,
						disabled = false,
						monthNavigator = false,
						yearNavigator = false,
						yearRange,
						minDate,
						maxDate,
						showTime = false,
						showSeconds = false,
						required = false
					}) => {
	const [field, meta] = useField(fieldName);
	const [fieldYear, metaYear] = useField(fieldYearName);
	const [fieldType] = useField(fieldTypeName);
	const [buttonChecked, setButtonChecked] = useState(fieldType.value === 'DATE');
	const onDateChange = (e) => {
		if (typeof e.target.value === "string") {
			return
		}
		field.onChange({target: { value: e.value, name: fieldName}})
		fieldYear.onChange({target: { value: (e.value !== '' ? moment(e.value).year() : null), name: fieldYearName}})
	}

	addLocale('hu', CalendarLocale)

	return (<div className='date-year-editor'>
			<div className='form-group w-100'>
				{fieldLabel ? <label className={`${required ? 'required' : ''}`} htmlFor={'input' + field.name}>{fieldLabel}</label> : ''}
				<div className="p-inputgroup">
					<ToggleButton onLabel={dateLabel} offLabel={yearLabel} checked={buttonChecked}
						onChange={(e) => {
							let type = e.value === true ? 'DATE' : 'YEAR'
							fieldType.onChange({target: { value: type, name: fieldTypeName}})
							setButtonChecked(e.value)
							}}/>
					{!buttonChecked &&
						<InputText
							keyfilter={/[0-9]/}
							className={`form-control ${metaYear.error && metaYear.touched ? 'is-invalid' : ''}`}
							id={'input' + fieldYear.name}
							name={fieldYear.name}
							placeholder={placeholder || fieldLabel}
							value={fieldYear.value}
							onChange={e => {
								fieldYear.onChange(e)
								if (field.value !== '')
								{
									field.onChange({target: { value: '', name: fieldName}})
								}
							}}
							onBlur={fieldYear.onBlur}
							maxLength={4}/>}
					{buttonChecked &&
						<Calendar
							locale='hu'
							className={`form-control p-0 border-0 ${meta.error && meta.touched ? 'is-invalid' : ''}`}
							id={'input' + field.name}
							name={field.name}
							placeholder={`${placeholder || fieldLabel}${required ? ' *' : ''}`}
							value={field.value}
							onChange={onDateChange}
							monthNavigator={monthNavigator}
							yearNavigator={yearNavigator}
							yearRange={yearRange}
							minDate={minDate}
							maxDate={maxDate}
							onBlur={field.onBlur}
							disabled={disabled}
							dateFormat='yy.mm.dd.'
							showIcon
							showTime={showTime}
							showSeconds={showSeconds}
							hourFormat="24"
							icon='pi pi-calendar'
							required={required}
						/>}
				</div>
				<ErrorMessage name={fieldType.value === 'YEAR' ? fieldYear.name : fieldName} component="div" className="error-message"/>
			</div>
		</div>
	)
}

export default DateYearEditor
