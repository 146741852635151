import React, {useContext, useEffect} from 'react'
import {Logo} from "../../layout/Menu";
import {Panel} from "primereact/panel";
import imgSuccess from "../../images/tick-128.png"
import imgError from "../../images/error-128.png"
import {withRouter} from "react-router";
import {Link} from "react-router-dom";
import gql from "graphql-tag";
import {useMutation} from "@apollo/client";
import {createErrorMessage} from "./LoginPage";
import {UserContext} from "../../components/UserContext";

const ACTIVATION = gql`
    mutation Activation($aktivalas: Aktivalas!) {
        activation(aktivalas: $aktivalas)
    }
`

const UserActivation = ({ children, match, ...rest }) => {
	const userContext = useContext(UserContext)
	if (userContext.loggedInUser) {
		userContext.logoutUser()
	}
	const [activation, { data, error: mutationError }] = useMutation(ACTIVATION, {
		variables: {
			"aktivalas": {
				"userId": match.params.id,
				"token": match.params.kod
			}}});
	useEffect(()=>{
		activation().then()
	}, [activation]) //ha azt akarjuk, hogy csak 1x fusson le -miután az oldal betöltődött- akkor kell az üres tömb 2. paraméternek
	return (
		<div className="main-page-content user-activation-page">
			<div className="d-flex h-100 justify-content-center flex-column">
				<div className="logo-wrapper d-flex justify-content-center">
					<div className="d-flex flex-column align-self-center">
						<div className="col"><Logo/></div>
						<div className="col mt-4">
							<Panel>
								<div className="d-flex align-items-center">
									<div className="col-auto p-0">
										{(data && data.activation === 'SUCCESS') ?
											<img src={imgSuccess} alt="success"/> :
											<img src={imgError} alt="error"/>
										}
									</div>
									{(data && data.activation === 'SUCCESS') ?
										<div className="col p-0 px-3">
										  <span className="font-weight-bold">
											Sikeres felhasználó aktiválás!
										  </span><br/>Most már bejelentkezhet!
										</div> :
										<div className="col p-0 px-3">
										  <span className="font-weight-bold">
											A felhasználó aktiválása nem sikerült!
										  </span><br/>
											{mutationError ?
											createErrorMessage( mutationError) :
												'Kérem ellenőrizze, hogy helyesen adta-e meg a linket!'}
										</div>
									}
								</div>

									<div className='row my-1'>
										<div className='col-12 col-md-12 cursor-pointer text-center'>
											<Link to="/">{data && data.activation === 'SUCCESS' ? 'Bejelentkezés' : 'Vissza a nyilólapra'}</Link>
										</div>
									</div>

							</Panel>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
export default withRouter(UserActivation)
