import React, {useContext, useEffect, useMemo, useRef, useState} from 'react'
import {useMutation, useQuery} from '@apollo/client'
import {Form, Formik, useField} from 'formik'
import {Button} from 'primereact/button'
import TextEditor from '../../components/editors/TextEditor'
import {LIST_SOROZAT} from '../../layout/Menu'
import ComboEditor from '../../components/editors/ComboEditor'
import PriceEditor from '../../components/editors/PriceEditor'
import {getEnumValue, getEnumValueOrEnumName} from '../EnumUtils'
import TextAreaEditor from '../../components/editors/TextAreaEditor'
import moment from 'moment'
import CheckboxEditor from '../../components/editors/CheckboxEditor'
import FileUploadEditor from '../../components/editors/FileUploadEditor'
import {Image} from './ListPage'
import gql from 'graphql-tag'
import {formatDateForSubmit} from '../Common'
import NumberEditor from '../../components/editors/NumberEditor'
import DateYearEditor from '../../components/editors/DateYearEditor'
import {UserContext} from '../../components/UserContext'
import {Prompt, withRouter} from 'react-router'
import {createErrorGrowl} from '../user/LoginPage'
import {BankjegyContext} from '../../components/BankjegyContext'
import {GET_BANKJEGY} from './BankjegyPage'
import * as Yup from 'yup'
import {setLocale} from 'yup'
import AutoCompleteEditor from '../../components/editors/AutoCompleteEditor'
import imgNew16 from '../../images/new-w-16.png'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {InputText} from 'primereact/inputtext'
import {Dialog} from 'primereact/dialog'
import {Messages} from 'primereact/messages'
import {InputNumber} from 'primereact/inputnumber'

const MODIFY_BANKJEGY = gql`
    mutation ModifyBankjegy($bankjegyModositas: BankjegyModositas!, $elooldal: Upload, $elooldaliLeiras: String, $hatoldal: Upload, $hatoldaliLeiras: String) {
        modifyBankjegy(bankjegyModositas: $bankjegyModositas, elooldal: $elooldal, elooldaliLeiras: $elooldaliLeiras, hatoldal: $hatoldal, hatoldaliLeiras: $hatoldaliLeiras) {
            id
        }
    }
`
const NEW_BANKJEGY = gql`
    mutation NewBankjegy($bankjegyUj: BankjegyUj!, $elooldal: Upload, $elooldaliLeiras: String, $hatoldal: Upload, $hatoldaliLeiras: String) {
        saveNewBankjegy(bankjegyUj: $bankjegyUj, elooldal: $elooldal, elooldaliLeiras: $elooldaliLeiras, hatoldal: $hatoldal, hatoldaliLeiras: $hatoldaliLeiras) {
            id
        }
    }
`

export const LIST_NEVERTEK_EGYSEG = gql`
    query ListNevertekEgyseg($penznem: Penznem!) {
        listNevertekEgyseg(penznem: $penznem)
    }
`

const GET_POSSIBLE_TIPUS = gql`
    query getPossibleTipus {
        getPossibleTipus
    }
`

const UNCValueEditor = ({fieldName}) => {
	const [field, ,helper] = useField(fieldName)
	const messageRef = useRef(null)
	const [componentValue, setComponentValue] = useState(() => {
		let initValue = []
		if (field.value && field.value.length > 0) {
			initValue = field.value.map(v => ({id: v.id, ertek: v.ertek, betujel: v.betujel}))
				.sort((v1, v2) => ((v1.betujel > v2.betujel) ? 1 : (v2.betujel > v1.betujel) ? -1 : 0))
			helper.setValue(initValue)
		}
		return initValue
	})

	const onEditorValueChangeForRowEditing = (props, value) => {
		let valuesForModify = [...componentValue]
		value = props.field === 'ertek' && !value ? null : value
		valuesForModify[props.rowIndex][props.field] = value
		setComponentValue(valuesForModify)
		helper.setValue(valuesForModify)
		props.editorCallback(value)
		const betujelek = valuesForModify.map(m=>m.betujel)
		if (betujelek.length !== new Set(betujelek).size )
		{
			let message = 'A betűjelek között duplikátum van.'
			helper.setError(message)
				messageRef.current.clear()
				messageRef.current.replace({severity: 'error', summary: null, detail: message, life: 20000, closable: false})
		} else {
			messageRef.current.clear()
		}
	}
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const [selectedRow, setSelectedRow] = useState(null)
	const onModalClose = () => {
		setModalIsOpen(false)
	}
	return <>
		<button className="brown-button" type="button" onClick={() => {
			let bankjegyBetujelekForModify = componentValue ? [...componentValue] : []
			bankjegyBetujelekForModify.push({id:`#${componentValue.length}`})
			setComponentValue(bankjegyBetujelekForModify)
		}
		}>
			<div className="d-inline-flex align-items-center" title="Új bankjegy betűjel felvétele">
				<img src={imgNew16} alt="new-UNC-value"/>
			</div>
		</button>
		<Dialog
			header="Törlés"
			visible={modalIsOpen}
			onHide={onModalClose}>
			<div className="">
				<div>Biztosan törli a betűjelet?</div>
				<div className="buttons mt-2">
					<Button label="Igen" className="green-button" onClick={() => {
						let valuesForModify = [...componentValue]
						const indexForDelete = valuesForModify.findIndex(f => f.id === selectedRow.id)
						let o1 = valuesForModify.slice(0, indexForDelete)
						let o2 = valuesForModify.slice(indexForDelete + 1)
						valuesForModify = [...o1, ...o2]
						setComponentValue(valuesForModify)
						helper.setValue(valuesForModify)
						setModalIsOpen(false)
						setSelectedRow(null)
					}}/>
					<Button label="Nem" className="green-button" onClick={() => {
						setModalIsOpen(false)
						setSelectedRow(null)
					}}/>
				</div>
			</div>
		</Dialog>
		<DataTable
			autoLayout={false}
			value={componentValue}
			editMode="cell"
			className="editable-cells-table"
			emptyMessage="Nincs adat!">
			<Column
				field="betujel"
				header="Betűjel"
				style={{height: '3.2rem'}}
				editor={(props) => <InputText
					type="text"
					className="w-100"
					value={props.value}
					onChange={(e) => onEditorValueChangeForRowEditing(props, e.target.value)}
				/>
				}
			/>
			<Column
				field="ertek"
				header="Érték"
				editor={(props) => <InputNumber
					className="w-100"
					value={props.rowData[props.field] || null}
					onChange={(e) => onEditorValueChangeForRowEditing(props, e.value)}
				/>
				}
			/>
			<Column style={{width: '2.5rem', cursor: 'pointer'}} body={row => {
				return <span className="pi pi-times" onClick={()=> {
					setSelectedRow(row)
					setModalIsOpen(true)
				}}></span>
			}}/>
		</DataTable>
		<Messages className="mt-3" ref={messageRef}/>
	</>
}

const EditBankjegyForm = withRouter((props) => {
	let bankjegy = props.value
	let history = props.history
	const bankjegyContext = useContext(BankjegyContext)
	const userContext = useContext(UserContext)
	let sorozatId = bankjegy ? bankjegy.sorozat.id : bankjegyContext.sorozatId
	const formRef = useRef(null)
	let penznem = bankjegyContext.penznem && JSON.parse(bankjegyContext.penznem)
	let [currency, setCurrency] = useState(bankjegy ? bankjegy.penznem : (penznem ? penznem.key : undefined))
	useMemo(() => getEnumValue('Penznem', currency).toLowerCase(), [currency])

	let [possibleSorozat, setPossibleSorozat] = useState([])
	let [possiblePenznem, setPossiblePenznem] = useState([])
	let [possibleTipus, setPossibleTipus] = useState([])
	let [possibleNevertekEgyseg, setPossibleNevertekEgyseg] = useState([])
	useEffect(() => {
		let res = [{label: 'Válasszon!', value: null}]
		userContext.possiblePenznem.forEach((value, index) => (
			res = [...res, {label: getEnumValue('Penznem', value), value: value}]
		))
		setPossiblePenznem(res)

	}, [userContext.possiblePenznem])
	useQuery(LIST_SOROZAT, {
		variables: {penznem: currency},
		onCompleted(data) {
			const sorozatList = data.listSorozatByPenznem
			let res = [{label: 'Válasszon!', value: null}]
			sorozatList.forEach((value, index) => (
				res = [...res, {label: value.nev, value: value.id}]
			))
			setPossibleSorozat(res)
		}
	})

	useQuery(GET_POSSIBLE_TIPUS, {
		fetchPolicy:'network-only',
		onCompleted(data) {
			const list = data.getPossibleTipus
			let res = [{label: 'Válasszon!', value: null}]
			list.forEach((value, index) => (
				res = [...res, {label: getEnumValueOrEnumName("Tipus", value), value: value}]
			))
			setPossibleTipus(res)
		}
	})

	useQuery(LIST_NEVERTEK_EGYSEG, {
		fetchPolicy: 'network-only', variables: {penznem: currency},
		onCompleted(data) {
			const nevertekEgysegList = data.listNevertekEgyseg
			let res = []
			nevertekEgysegList.forEach((value, index) => (
				res = [...res, {label: value, value: value}]
			))
			setPossibleNevertekEgyseg(res)
		}
	})

	const queryMethod = (e, qValue) => {
		return qValue.toLowerCase().startsWith(e.query.toLowerCase())
	}

	const initialValues = {
		sorrend: (bankjegy && bankjegy.sorrend) || '',
		penznem: currency,
		sorozat: sorozatId,
		nevertek: (bankjegy && bankjegy.nevertek) || '',
		nevertekEgyseg: (bankjegy && bankjegy.nevertekEgyseg) || '',
		meret: (bankjegy && bankjegy.meret) || '',
		tipus: (bankjegy && bankjegy.tipus) || '',
		megjegyzes: (bankjegy && bankjegy.megjegyzes) || '',
		keltezesDatuma: (bankjegy && bankjegy.keltezesDatuma) ? new Date(bankjegy.keltezesDatuma) : '',
		keltezesEve: (bankjegy && bankjegy.keltezesEve) || '',
		keltezesType: (bankjegy && bankjegy.keltezesEve && !bankjegy.keltezesDatuma) ? 'YEAR' : 'DATE',
		kibocsajtasDatuma: (bankjegy && bankjegy.kibocsajtasDatuma) ? new Date(bankjegy.kibocsajtasDatuma) : '',
		kibocsajtasEve: (bankjegy && bankjegy.kibocsajtasEve) || '',
		kibocsajtasType: (bankjegy && bankjegy.kibocsajtasEve && !bankjegy.kibocsajtasDatuma) ? 'YEAR' : 'DATE',
		bevonasDatuma: (bankjegy && bankjegy.bevonasDatuma) ? new Date(bankjegy.bevonasDatuma) : '',
		bevonasEve: (bankjegy && bankjegy.bevonasEve) || '',
		bevonasType: (bankjegy && bankjegy.bevonasEve && !bankjegy.bevonasDatuma) ? 'YEAR' : 'DATE',
		forgalomban: (bankjegy && bankjegy.forgalomban) || false,
		atvaltasDatuma: (bankjegy && bankjegy.atvaltasDatuma) ? new Date(bankjegy.atvaltasDatuma) : '',
		atvaltasEve: (bankjegy && bankjegy.atvaltasEve) || '',
		atvaltasType: (bankjegy && bankjegy.atvaltasEve && !bankjegy.atvaltasDatuma) ? 'YEAR' : 'DATE',
		ertekUnc: (bankjegy && bankjegy.ertekUnc) || '',
		ertekEf: (bankjegy && bankjegy.ertekEf) || '',
		ertekVf: (bankjegy && bankjegy.ertekVf) || '',
		elooldalLeiras: (bankjegy && bankjegy.elooldal.leiras) || '',
		hatoldalLeiras: (bankjegy && bankjegy.hatoldal.leiras) || '',
		elooldalBelyegKep: (bankjegy && bankjegy.elooldal.belyegKep) || '',
		elooldalMegjelenoKep: (bankjegy && bankjegy.elooldal.megjelenoKep) || '',
		elooldalEredetiKep: (bankjegy && bankjegy.elooldal.eredetiKep) || '',
		hatoldalBelyegKep: (bankjegy && bankjegy.hatoldal.belyegKep) || '',
		hatoldalMegjelenoKep: (bankjegy && bankjegy.hatoldal.megjelenoKep) || '',
		hatoldalEredetiKep: (bankjegy && bankjegy.hatoldal.eredetiKep) || '',
		tobbUncErtek: (bankjegy && bankjegy.tobbUncErtek) || '',
		bankjegyBetujelek: (bankjegy && bankjegy.bankjegyBetujelek) || null,
		elooldalUjKep: null,
		hatoldalUjKep: null
	}
	/* eslint-disable no-template-curly-in-string */
	setLocale({
		mixed: {
			required: 'A mező kitöltése kötelező!'
		},
		string: {
			max: 'A mezőnek maximum ${max} karakter hosszúnak lehet lennie!'
		},
		number: {
			min: 'A legkisebb megadható érték: ${min}!',
			max: 'A legnagyobb megadható érték: ${max}!'
		}
	})

	const editBankjegyValidationSchema = Yup.object().shape({
		meret: Yup.string().max(255).matches(/^[0-9]{1,4}x[0-9]{1,4}$/i, 'A méret érvénytelen. Megadási mód: [szélesség mm-ben]x[magasság mm-ben]. Például: 121x80 !'),
		keltezesEve: Yup.number().nullable().min(1800).max(2100),
		kibocsajtasEve: Yup.number().nullable().min(1800).max(2100),
		bevonasEve: Yup.number().nullable().min(1800).max(2100),
		atvaltasEve: Yup.number().nullable().min(1800).max(2100),
		tipus: Yup.mixed().required(),
		penznem: Yup.mixed().required(),
		sorrend: Yup.mixed().required(),
		sorozat: Yup.mixed().required(),
		megjegyzes: Yup.string().max(4000),
		elooldalLeiras: Yup.string().max(4000),
		hatoldalLeiras: Yup.string().max(4000)
	})

	const [modifyBankjegy] = useMutation(MODIFY_BANKJEGY, {
		onCompleted({modifyBankjegy}) {
			if (modifyBankjegy) {
				userContext.growl({severity: 'info', summary: 'Sikeres mentés!'})
				// eslint-disable-next-line no-unused-expressions
				if (bankjegyContext.editBankjegyFromPage === 'listPage') {
					history.push({pathname: '/lista/' + currency + '/' + sorozatId})
				} else if (bankjegyContext.editBankjegyFromPage === 'bankjegyPage') {
					history.push({pathname: '/bankjegy/' + sorozatId + '/' + bankjegy.id})
				} else {
					history.goBack()
				}
			}
		},
		onError(error) {
			createErrorGrowl(error, 'Sikertelen mentés!', userContext, 20000)
		},
		refetchQueries: [{
			query: GET_BANKJEGY,
			variables: {id: bankjegy && bankjegy.id}

		}]
	})
	const [saveNewBankjegy] = useMutation(NEW_BANKJEGY, {
		onCompleted({saveNewBankjegy}) {
			if (saveNewBankjegy) {
				userContext.growl({severity: 'info', summary: 'Sikeres mentés!'})
				// eslint-disable-next-line no-unused-expressions
				if (bankjegyContext.editBankjegyFromPage === 'listPage') {
					history.push({pathname: '/lista/' + currency + '/' + sorozatId})
				} else {
					history.goBack()
				}

			}
		},
		onError(error) {
			createErrorGrowl(error, 'Sikertelen mentés!', userContext, 20000)
		}
	})
	const submit = (values) => {
		if (bankjegy?.id) {
			modifyBankjegy({
				variables: {
					bankjegyModositas: {
						id: bankjegy.id,
						sorrend: values.sorrend,
						penznem: values.penznem,
						sorozatId: values.sorozat,
						tipus: values.tipus,
						meret: values.meret,
						keltezesDatuma: (values.keltezesType === 'DATE' && values.keltezesDatuma) ? formatDateForSubmit(values.keltezesDatuma) : null,
						keltezesEve: (values.keltezesType === 'YEAR' && values.keltezesEve) ? values.keltezesEve : null,
						forgalomban: values.forgalomban,
						kibocsajtasDatuma: (values.kibocsajtasType === 'DATE' && values.kibocsajtasDatuma) ? formatDateForSubmit(values.kibocsajtasDatuma) : null,
						kibocsajtasEve: (values.kibocsajtasType === 'YEAR' && values.kibocsajtasEve) ? values.kibocsajtasEve : null,
						bevonasDatuma: (values.bevonasType === 'DATE' && values.bevonasDatuma) ? formatDateForSubmit(values.bevonasDatuma) : null,
						bevonasEve: (values.bevonasType === 'YEAR' && values.bevonasEve) ? values.bevonasEve : null,
						atvaltasDatuma: (values.atvaltasType === 'DATE' && values.atvaltasDatuma) ? formatDateForSubmit(values.atvaltasDatuma) : null,
						atvaltasEve: (values.atvaltasType === 'YEAR' && values.atvaltasEve) ? values.atvaltasEve : null,
						nevertek: values.nevertek || null,
						nevertekEgyseg: values.nevertekEgyseg,
						ertekUnc: values.ertekUnc || null,
						ertekEf: values.ertekEf || null,
						ertekVf: values.ertekVf || null,
						megjegyzes: values.megjegyzes,
						bankjegyBetujelek: (values.bankjegyBetujelek && values.bankjegyBetujelek.map(b=>(b.id.includes('#') ? {ertek: b.ertek, betujel: b.betujel} : {...b})))  || null,
						tobbUncErtek: values.tobbUncErtek || null
					},
					elooldal: values.elooldalUjKep,
					elooldaliLeiras: values.elooldalLeiras,
					hatoldal: values.hatoldalUjKep,
					hatoldaliLeiras: values.hatoldalLeiras
				}
			}).then()
		} else {
			saveNewBankjegy({
				variables: {
					bankjegyUj: {
						sorrend: values.sorrend,
						penznem: values.penznem,
						sorozatId: values.sorozat,
						tipus: values.tipus,
						meret: values.meret,
						keltezesDatuma: (values.keltezesType === 'DATE' && values.keltezesDatuma) ? formatDateForSubmit(values.keltezesDatuma) : null,
						keltezesEve: (values.keltezesType === 'YEAR' && values.keltezesEve) ? values.keltezesEve : null,
						forgalomban: values.forgalomban,
						kibocsajtasDatuma: (values.kibocsajtasType === 'DATE' && values.kibocsajtasDatuma) ? formatDateForSubmit(values.kibocsajtasDatuma) : null,
						kibocsajtasEve: (values.kibocsajtasType === 'YEAR' && values.kibocsajtasEve) ? values.kibocsajtasEve : null,
						bevonasDatuma: (values.bevonasType === 'DATE' && values.bevonasDatuma) ? formatDateForSubmit(values.bevonasDatuma) : null,
						bevonasEve: (values.bevonasType === 'YEAR' && values.bevonasEve) ? values.bevonasEve : null,
						atvaltasDatuma: (values.atvaltasType === 'DATE' && values.atvaltasDatuma) ? formatDateForSubmit(values.atvaltasDatuma) : null,
						atvaltasEve: (values.atvaltasType === 'YEAR' && values.atvaltasEve) ? values.atvaltasEve : null,
						nevertek: values.nevertek || null,
						nevertekEgyseg: values.nevertekEgyseg,
						ertekUnc: values.ertekUnc || null,
						ertekEf: values.ertekEf || null,
						ertekVf: values.ertekVf || null,
						megjegyzes: values.megjegyzes,
						bankjegyBetujelek: (values.bankjegyBetujelek && values.bankjegyBetujelek.map(b=>(b.id.includes('#') ? {ertek: b.ertek, betujel: b.betujel} : {...b})))  || null,
						tobbUncErtek: values.tobbUncErtek || null
					},
					elooldal: values.elooldalUjKep,
					elooldaliLeiras: values.elooldalLeiras,
					hatoldal: values.hatoldalUjKep,
					hatoldaliLeiras: values.hatoldalLeiras
				}
			}).then(()=>{
				userContext.possiblePenznemRefetch()
			})
		}
	}
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const onModalClose = () => {
		setModalIsOpen(false)
	}
	return (
		<>{userContext.loggedInUser.szerep !== 'ADMIN' ?
			<div className="alert alert-warning mx-3" role="alert">Nincs megfelelő jogosultsága!</div> :
			<>
				<Dialog
					header="Törlés"
					visible={modalIsOpen}
					onHide={onModalClose}>
					<div className="">
						<div>Vannak rögzített betűjelek a bankjegyhez, melyek törlésre kerülnek mentés után, ha a "Több UNC érték" nincs kiválasztva.</div>
						<div className="buttons mt-2">
							<Button label="Bezár" className="green-button" onClick={() => {
								setModalIsOpen(false)
							}}/>
						</div>
					</div>
				</Dialog>
				<Formik
					initialValues={initialValues}
					validationSchema={editBankjegyValidationSchema}
					onSubmit={submit}
				>
					{({setFieldValue, values, errors, isSubmitting}) => (
						<Form ref={formRef} className="search-form">
							<Prompt
								when={!isSubmitting && JSON.stringify(values) !== JSON.stringify(initialValues)}
								message="El nem mentett adatok vannak az oldalon. Biztosan elhagyja az oldalt?"
							/>
							<div className="row">
								<div className="col-12 col-sm-6 mb-3">
									<NumberEditor required focus fieldLabel="Sorrend" fieldName="sorrend"/>
								</div>
								<div className="col-12 col-sm-6 mb-3">
									<ComboEditor required fieldLabel="Pénznem" fieldName="penznem" possibleValues={possiblePenznem}
										onChange={(value) => {
											setFieldValue('sorozat', null)
											setCurrency(value)
										}}/>
								</div>
								<div className="col-12 col-sm-6 mb-3">
									<ComboEditor required fieldLabel="Sorozat" fieldName="sorozat" possibleValues={possibleSorozat}/>
								</div>
								<div className="col-12 col-sm-6 mb-3">
									<TextEditor fieldName="meret" fieldLabel="Méret" keyfilter={/[0-9x]/} inputGroupAfter="mm"/>
								</div>
								<div className="col-12 col-sm-6 mb-3">
									<NumberEditor useGrouping fieldName="nevertek" fieldLabel="Névérték"/>
								</div>
								<div className="col-12 col-sm-6 mb-3">
									{ //<TextEditor fieldName='nevertekEgyseg' fieldLabel='Névérték egység'/
									}
									<AutoCompleteEditor
										fieldLabel="Névérték egység"
										fieldName="nevertekEgyseg"
										possibleValues={possibleNevertekEgyseg}
										queryMethod={queryMethod}
										dropdown
									/>
								</div>
								<div className="col-12 col-sm-6 mb-3">
									<AutoCompleteEditor
										dropdown
										required
										fieldLabel="Típus"
										fieldName="tipus"
										possibleValues={possibleTipus}
										queryMethod={queryMethod}
										/>
								</div>
							</div>
							<div className="row">
								<div className="col-12 mb-3">
									<TextAreaEditor fieldName="megjegyzes" fieldLabel="Megjegyzés" rows={4}/>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-sm-6 mb-3">
									<DateYearEditor fieldName="keltezesDatuma" fieldYearName="keltezesEve" fieldTypeName="keltezesType" fieldLabel="Keltezés" yearLabel="éve" dateLabel="dátuma"
										maxDate={new Date()} monthNavigator={true} yearNavigator={true} yearRange={'1848:' + moment().format('YYYY')}/>
								</div>
								<div className="col-12 col-sm-6 mb-3">
									<DateYearEditor fieldName="kibocsajtasDatuma" fieldYearName="kibocsajtasEve" fieldTypeName="kibocsajtasType" fieldLabel="Kibocsátás" yearLabel="éve"
										dateLabel="dátuma"
										maxDate={new Date()} monthNavigator={true} yearNavigator={true} yearRange={'1848:' + moment().format('YYYY')}/>
								</div>
								<div className="col-12 col-sm-6 mb-3">
									<DateYearEditor fieldName="bevonasDatuma" fieldYearName="bevonasEve" fieldTypeName="bevonasType" fieldLabel="Bevonás" yearLabel="éve" dateLabel="dátuma"
										monthNavigator={true} yearNavigator={true} yearRange={'1848:' + moment().add(50, 'year').format('YYYY')}/>
								</div>
								<div className="col-12 col-sm-6 mb-3">
									<DateYearEditor fieldName="atvaltasDatuma" fieldYearName="atvaltasEve" fieldTypeName="atvaltasType" fieldLabel="Átváltás" yearLabel="éve" dateLabel="dátuma"
										monthNavigator={true} yearNavigator={true} yearRange={'1848:' + moment().add(50, 'year').format('YYYY')}/>
								</div>
								<div className="col-12 col-sm-6 mb-3">
									<CheckboxEditor fieldName="forgalomban" fieldLabel="Forgalomban"/>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-sm-6 mb-3">
									<CheckboxEditor fieldName="tobbUncErtek" fieldLabel="Több UNC érték" onChange={(checked => {
										if (!checked && values.bankjegyBetujelek && values.bankjegyBetujelek.length > 0) {
											setModalIsOpen(true)
										}
									})}/>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-sm-6 mb-3">
									{values.tobbUncErtek ?
										<UNCValueEditor fieldName="bankjegyBetujelek"/>
										:
										<PriceEditor fieldName="ertekUnc" fieldLabel="ÉRTÉK UNC" currency="Ft"/>}
								</div>
								<div className="col-12 col-sm-6 mb-3">
									<PriceEditor fieldName="ertekEf" fieldLabel="ÉRTÉK EF" currency="Ft"/>
								</div>
								<div className="col-12 col-sm-6 mb-3">
									<PriceEditor fieldName="ertekVf" fieldLabel="ÉRTÉK VF" currency="Ft"/>
								</div>
							</div>

							<div className="row">
								<div className="col-12 col-sm-6 mb-3">
									<TextAreaEditor fieldName="elooldalLeiras" fieldLabel="Előoldal leírás" rows={4}/>
									<FileUploadEditor fieldName="elooldalUjKep" fieldLabel="Előoldal kép" previewLabel="Új előoldal kép">
										<Image tinyImage={values.elooldalBelyegKep} hugeImage={values.elooldalMegjelenoKep} className="mb-2"/>
									</FileUploadEditor>
								</div>
								<div className="col-12 col-sm-6 mb-3">
									<TextAreaEditor fieldName="hatoldalLeiras" fieldLabel="Hátoldal leírás" rows={4}/>
									<FileUploadEditor fieldName="hatoldalUjKep" fieldLabel="Hátoldal kép" previewLabel="Új hátoldal kép">
										<Image tinyImage={values.hatoldalBelyegKep} hugeImage={values.hatoldalMegjelenoKep} className="mb-2"/>
									</FileUploadEditor>
								</div>
							</div>
							{Object.keys(errors).length > 0 &&
							<div className="error-message mb-3">Kérem, ellenőrizze az adatokat!</div>}
							<div className="buttons">
								{(!bankjegy || bankjegy.statusz === 'AKTIV'
								) && <Button
									className="brown-button"
									label="Mentés"
									type="submit"/>}
								<Button
									type="button"
									className="green-button"
									onClick={() => {
										history.goBack()
									}}
									label="Mégsem"
								/>
							</div>
						</Form>
					)}
				</Formik>
			</>
		}</>
	)
}
)

export default EditBankjegyForm
