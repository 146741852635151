import React from 'react'
import NumberEditor from './NumberEditor'
import {formatNumber} from '../../routes/Common'

const PriceEditor = React.forwardRef(({
	fieldName,
	fieldLabel,
	className,
	labelClassName,
	placeholder,
	disabled = false,
	children,
	focus = false,
	readonly = false,
	required,
	min,
	max,
	numbers,
	decimals,
	overwritableAddonFieldName,
	overwritableWorksInReverse,
	onChange,
	onBlur,
	immediately,
	labelSize,
	editorSize,
	currency='Ft'
					 }, ref) => {
	const readonlyValueFormatterFunc = (value) => {
		if (value) {
			return formatNumber(value) + ' ' + (currency ?? '')
		}
	}
	return (

		<NumberEditor
			fieldName={fieldName} fieldLabel={fieldLabel} className={className} labelClassName={labelClassName} placeholder={placeholder}
			disabled={disabled} children={children} focus={focus} readonly={readonly} inputGroupAfter={currency ?? ''} required={required}
			numbers={numbers} decimals={decimals}
			min={min}
			max={max}
			overwritableAddonFieldName={overwritableAddonFieldName}
			overwritableWorksInReverse={overwritableWorksInReverse}
			useGrouping={true}
			readonlyValueFormatter={readonlyValueFormatterFunc}
			onChange={onChange}
			onBlur={onBlur}
			immediately={immediately}
			labelSize={labelSize}
			editorSize={editorSize}
			ref={ref}
		/>
	)})

export default PriceEditor
