import React, {useContext, useState} from 'react'
import {Collapse} from 'react-collapse'
import {Link, withRouter} from 'react-router-dom'
import gql from 'graphql-tag'
import SearchPanel from './SearchPanel'
import imgGallery from '../images/gallery-32.png'
import {BankjegyContext} from '../components/BankjegyContext'
import {getEnumValue} from '../routes/EnumUtils'
import UserHeaderPanel from '../routes/user/UserHeaderPanel'
import {UserContext} from '../components/UserContext'
import useViewport from '../routes/useViewport'
import {useQuery} from '@apollo/client'

const LIST_SOROZAT_BY_POSSIBLE_PENZNEM = gql`
    query ListSorozatByPossiblePenznem($penznem: [Penznem]!) {
        listSorozatByPossiblePenznem(penznem: $penznem) {
            penznem
            sorozat {
                nev
                id
            }
        }
    }
`

export const LIST_SOROZAT = gql`
    query ListSorozatByPenznem($penznem: Penznem!) {
        listSorozatByPenznem(penznem: $penznem) {
            nev
            id
        }
    }
`

const DropDownNavbarLinkWithCollapse = ({children, label, className}) => {
	const [dropDownCollapseVisible, setDropDownCollapseVisible] = useState(localStorage.getItem('bcSelectedDropDown' + label) === 'true')
	return (
		<li className={`nav-item active dropdown ${className}`}>
			<span className="nav-link dropdown-toggle cursor-pointer"
				onClick={e => {
					setDropDownCollapseVisible(!dropDownCollapseVisible)
					localStorage.setItem('bcSelectedDropDown' + label, !dropDownCollapseVisible)
				}}>
				{label}
			</span>
			<Collapse isOpened={dropDownCollapseVisible}>
				<div className="collapse-menu">
					{children}
				</div>
			</Collapse>

		</li>
	)
}
const DropDownNavbarLinkWithoutCollapse = ({children, label, className}) => {
	return (
		<li className={`nav-item active dropdown ${className}`}>
			<span className="nav-link cursor-pointer">
				{label}
			</span>
			<div className="collapse-menu">
				{children}
			</div>
		</li>
	)
}

const DropDownNavbarLink = ({children, label}) => {
	return (
		<React.Fragment>
			<DropDownNavbarLinkWithCollapse label={label} className="d-none d-md-block">
				{children}
			</DropDownNavbarLinkWithCollapse>
			<DropDownNavbarLinkWithoutCollapse label={label} className="d-block d-md-none">
				{children}
			</DropDownNavbarLinkWithoutCollapse>
		</React.Fragment>
	)
}

const DropDownNavbarLinks = withRouter(({penznemKey, penznemValue, values, selectedSorozatId, match, location, history}) => {
	const bankjegyContext = useContext(BankjegyContext)
	return (
		<ul className="navbar-nav flex-column mr-auto">
			<DropDownNavbarLink label={penznemValue}>
				{values.map((value, index) => {
					let count = null
					return (<div key={`dropdown_navbar_links_${value.id}`} className={`dropdown-item-wrapper mb-1 ${value.id === selectedSorozatId ? 'active' : ''}`}>
						<Link className="dropdown-item" to={{pathname: `/lista/${penznemKey.toLowerCase()}/${value.id}`}} onClick={
							(e) => {
								sessionStorage.setItem('bcSearchText', '')
								bankjegyContext.setListPageScrollPosition(0)
								bankjegyContext.setSelectedBankjegyId(undefined)
								bankjegyContext.setPenznem(JSON.stringify({key: penznemKey, value: penznemValue}))
								bankjegyContext.setSorozatId(value.id)
							}
						}>{value.nev} {count ? ('(' + count + ')') : ''}</Link>
					</div>)
				})}
			</DropDownNavbarLink>
		</ul>
	)
})

export const Logo = () => {
	const bankjegyContext = useContext(BankjegyContext)
	let distribType = bankjegyContext.distribType
	if (distribType) {
		distribType = distribType.toLowerCase()
	}
	return <a className="navbar-brand align-self-center" href="/">
		<span className="logo">Bankjegy</span>
		<span className="logo-portal">portál</span>
		<span className={`distrib-type ${distribType ? distribType : ''}`}>
			{bankjegyContext.distribType && getEnumValue('DistribType', bankjegyContext.distribType)}
		</span>
	</a>
}

const DropDownNavbarLinksMode = ({collapseButtonVisible, penznemKey, penznemValue, values, selectedSorozatId}) => {
	return (
		<>
			<div className="d-block d-md-none">
				<Collapse isOpened={collapseButtonVisible}>
					<DropDownNavbarLinks penznemKey={penznemKey} penznemValue={penznemValue} values={values} selectedSorozatId={selectedSorozatId}/>
				</Collapse>
			</div>
			<div className="d-none d-md-block">
				<DropDownNavbarLinks penznemKey={penznemKey} penznemValue={penznemValue} values={values} selectedSorozatId={selectedSorozatId}/>
			</div>
		</>
	)
}

export const Footer = ({className}) => {
	const userContext = useContext(UserContext)
	return <footer className={`p-3 text-center w-100 ${className ? className : ''}`}>
		<div className={`mb-3 d-none ${userContext.loggedInUser ? 'd-md-block' : ''}`}><img src={imgGallery} alt="money"/></div>
		<div>Copyright © {new Date().getFullYear()}, Majlinger <Link className="ml-2 text-decoration-none" to="/impresszum">Impresszum</Link>
			<a className="ml-2" href="/adatkezelesi_tajekoztato.pdf" download>Adatkezelési tájékoztató</a>
			<a className="ml-2" href="/felhasznaloi_kezikonyv.pdf" download>Felhasználói kézikönyv</a></div>

	</footer>
}

const Menu = withRouter(({match, location, history}) => {
	let params = history.location ? new URLSearchParams(history.location.search) : ''
	let wasSearch = !(params.get('searchText') === null || params.get('searchText').length === 0)
	const [collapseButtonVisible, setCollapseButtonVisible] = useState(false)
	let sorozatId = match.params.sorozatId
	const viewport = useViewport()
	const userContext = useContext(UserContext)

	const [sorozatByPenznem, setSorozatByPenznem] = useState(null)

	const listSorozatQuery = useQuery(LIST_SOROZAT_BY_POSSIBLE_PENZNEM, {
		skip: !userContext.loggedInUser || !userContext.possiblePenznem,
		variables: {penznem: userContext.possiblePenznem},
		onCompleted(data) {
			if (data && data.listSorozatByPossiblePenznem) {
				let initSorozatByPenznem = []
				let values = data.listSorozatByPossiblePenznem
				values.forEach(value => {
					let penznemKey = value.penznem
					let penznemValue = getEnumValue('Penznem', penznemKey)
					initSorozatByPenznem.push({penznemKey: penznemKey, penznemValue: penznemValue, data: value.sorozat})
				})
				setSorozatByPenznem(initSorozatByPenznem)
			}
		},
		onError(error) {
			console.log(JSON.stringify(error))
		}

	})

	return (
		<nav className="navbar navbar-expand-md flex-column align-items-start navbar-dark bg-dark flex-grow-1">
			<div className="container-fluid">
				<div className="row w-100 mb-2 gx-0">
					<div className="col pl-0 pr-0 text-md-center">
						<Logo/>
					</div>

					<div className="col col-md-auto pr-0 text-end">
						{['xs', 'sm'].includes(viewport) && <UserHeaderPanel theme="menu"/>}
						<div className="mt-3">
							<button className="navbar-toggler" type="green-button" onClick={e => {
								setCollapseButtonVisible(!collapseButtonVisible)
							}}
							>
								<span className="navbar-toggler-icon"></span>
							</button>
						</div>
					</div>
				</div>
			</div>
			<div className="mt-md-5 mt-3 w-100 text-center">
				<SearchPanel/>
			</div>
			<div className="mt-md-5 mx-3 mt-3 flex-grow-1">

				{listSorozatQuery.loading && <div>Betöltés folyamatban...</div>}
				{listSorozatQuery.error && <div>Hiba a betöltés közben! {console.log(listSorozatQuery.error)}</div>}
				{sorozatByPenznem && sorozatByPenznem.map((sorozat, idx) => {
					return <DropDownNavbarLinksMode
						values={sorozat.data}
						key={`${sorozat.penznemKey}${idx}`}
						penznemKey={sorozat.penznemKey}
						penznemValue={sorozat.penznemValue}
						collapseButtonVisible={collapseButtonVisible}
						selectedSorozatId={wasSearch ? '' : sorozatId}/>
				})}
			</div>
			<Footer className="d-none d-md-block"/>
		</nav>
	)
})

export default Menu
