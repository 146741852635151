/**
 * A Bankjegy Lista navigáláshoz egy context
 */

import React, {useEffect, useRef} from 'react'
import gql from "graphql-tag";
import {useQuery} from '@apollo/client';

export const BankjegyContext = React.createContext(null)

const GET_DISTRIB_TYPE = gql`{
    getDistribType
}`

export const useStateWithLocalStorage = (localStorageKey, initValue='') => {
	const [value, setValue] = React.useState(
		localStorage.getItem(localStorageKey) || initValue
	)
	/* eslint-disable */
	React.useEffect(() => {
		try {
			localStorage.setItem(localStorageKey, value)
		} catch (e) {
			console.log(e)
		}
	}, [value])
	return [value, setValue]
	/* eslint-enable */
}

export const useStateWithSessionStorage = sessionStorageKey => {
	const [value, setValue] = React.useState(
		sessionStorage.getItem(sessionStorageKey) || ''
	);
	return [
		value,
		(val) => {
			sessionStorage.setItem(sessionStorageKey, val)
			setValue(val)
		}
	]
};

export const BankjegyContextProvider = ({ children }) => {
	const [bankjegyIds, setBankjegyIds] = useStateWithSessionStorage('bankjegyValues')
	//a kiválasztott pénznem
	const [penznem, setPenznem] = useStateWithSessionStorage('selectedPenzem')
	const [sorozatId, setSorozatId] = useStateWithSessionStorage('selectedSorozat')
	//az aktuálisan kiválasztott bankjegy id-je
	// eslint-disable-next-line
	const [currentId, setCurrentId] = useStateWithSessionStorage('currentId');
	const [length, setLength] = useStateWithSessionStorage('length');
	//a sorozatban a bankjegy index-e, azaz hányadik elem a listában
	const [currentIdx, setCurrentIdx] = useStateWithSessionStorage('currentIdx');
	const [listPageScrollPosition, setListPageScrollPosition] = useStateWithSessionStorage('listPageScrollPosition');
	const [selectedBankjegyId, setSelectedBankjegyId] = useStateWithSessionStorage('selectedBankjegyId');
	// a ".main-inner" elem a DOM-ban (ez a scroll elem, pozició tároláshoz kell a ref)
	const mainInnerRef = useRef(null);

	const distribTypeQuery = useQuery(GET_DISTRIB_TYPE)
	//az az oldal ahonnan a bankjegyPage oldalra jöttünk lehet: "listPage", "bankjegyPage"
	const [editBankjegyFromPage, setEditBankjegyFromPage] = useStateWithSessionStorage('editBankjegyFromPage');
	//bankjegy megtekintás log-hoz, hogy egy megtekintéshez csak 1 log írodjon be
	const [lastLocation, setLastLocation] = useStateWithSessionStorage('lastLocation');
	const [locationChanged, setLocationChanged] = useStateWithSessionStorage('locationChanged');
	let [sortSorozat, setSortSorozat] = useStateWithLocalStorage('sortSorozat', '[]')

	useEffect(()=>{
		let bankjegyIdList = (bankjegyIds !== null && bankjegyIds.length > 0) ? JSON.parse(bankjegyIds) : []
		setLength(bankjegyIdList.length)
		// eslint-disable-next-line
	}, [bankjegyIds])


	useEffect(()=>{
		if (currentId) {
			let bankjegyIdList = (bankjegyIds !== null && bankjegyIds.length > 0) ? JSON.parse(bankjegyIds) : []
			for (let i=0; i < bankjegyIdList.length; i++) {
				if (bankjegyIdList[i].bankjegyId === currentId) {
					setCurrentIdx(bankjegyIdList[i].idx)
					break;
				}
			}
		}
		// eslint-disable-next-line
	}, [currentId])
	const DEFAULT_SORT = 'NORMAL#NOVEKVO'
	const getKeyForSortSorozat = ()=> {
		if (penznem && sorozatId) {
			let penznemValue = ''
			try {
				penznemValue = JSON.parse(penznem)
			} catch {
				console.warn('Incorrect penznem value')
			}

			return `${penznemValue.key}_${sorozatId}`
		}
	}


	return (
		<BankjegyContext.Provider
			value={{
				prevBankjegy:(currId) => {
					setCurrentId(currId)
					let previusItem = null;
					let bankjegyIdList = (bankjegyIds !== null && bankjegyIds.length > 0) ? JSON.parse(bankjegyIds) : []
					for (let i=0; i < bankjegyIdList.length; i++) {
						if (bankjegyIdList[i].bankjegyId === currId) {
							previusItem = bankjegyIdList[i-1 < 0 ? null : i-1]
							break;
						}
					}
					return previusItem;
				},
				nextBankjegy:(currId) => {
					let nextItem = null;
					let bankjegyIdList = (bankjegyIds !== null && bankjegyIds.length > 0) ? JSON.parse(bankjegyIds) : []
					for (let i=0; i<bankjegyIdList.length; i++) {
						if (bankjegyIdList[i].bankjegyId === currId) {
							nextItem = bankjegyIdList[i+1 > bankjegyIdList.length-1 ? null : i+1]
							break;
						}
					}
					return nextItem;
				},
				length: length,
				currentIdx: currentIdx,
				setBankjegyList: (bankjegyList)=> {
					let bankjegyIdList = []
					bankjegyList.map((value, index) => {
						return bankjegyIdList[index]={bankjegyId:value.id, sorozatId:value.sorozat.id, idx:index+1}
					})
					setBankjegyIds(JSON.stringify(bankjegyIdList))
					setListPageScrollPosition(mainInnerRef.current.scrollTop)
				},
				setListPageScrollPosition: setListPageScrollPosition,
				listPageScrollPosition:listPageScrollPosition,
				mainInnerRef: mainInnerRef,
				setSelectedBankjegyId:(selBankjegyId) => {
					setSelectedBankjegyId(selBankjegyId)
				},
				selectedBankjegyId: selectedBankjegyId,
				distribType: distribTypeQuery.data && distribTypeQuery.data.getDistribType,
				penznem: penznem,
				setPenznem: setPenznem,
				sorozatId: sorozatId,
				setSorozatId: setSorozatId,
				editBankjegyFromPage: editBankjegyFromPage,
				setEditBankjegyFromPage: setEditBankjegyFromPage,
				setLastLocation: (path) => {
					if (path !== lastLocation) {
						setLastLocation(path)
						setLocationChanged(true)
					} else {
						setLocationChanged(false)
					}
				},
				locationChanged:locationChanged,
				getSortSorozat:()=> {
					const sortValues = JSON.parse(sortSorozat)
					return sortValues?.find(f=>f.sorozat === getKeyForSortSorozat())?.value || DEFAULT_SORT
				},
				sortSorozat:sortSorozat,
				setSortSorozat:(sortValue)=>{
					setSortSorozat((oldValue) => {
						let valueFromLocal = JSON.parse(oldValue)
						valueFromLocal = valueFromLocal.filter(f=>f.sorozat !== getKeyForSortSorozat())
						if (sortValue !== DEFAULT_SORT) {
							valueFromLocal.push({sorozat:getKeyForSortSorozat(), value: sortValue})
						}
						return JSON.stringify(valueFromLocal)
					})
				}
			}}
		>
			{children}
		</BankjegyContext.Provider>

	)
}

export const BankjegyContextConsumer = BankjegyContext.Consumer
