import React, {useContext} from "react";
import {withRouter} from "react-router";
import {BankjegyContext} from "../components/BankjegyContext";
import {UserContext} from "../components/UserContext";
import Menu, {Footer} from "./Menu";

export const AdminLayoutContent = withRouter(({history, styleClass, matchProps, children, showMenu=true}) => {
	const bankjegyContext = useContext(BankjegyContext)
	const userContext = useContext(UserContext)
	return (
		<div className="content-layout default-layout">
			<div className={`container-fluid no-gutters p-0 ${styleClass ? styleClass : ''}`}>
				<div className="content-wrapper d-flex flex-column">
					{userContext.loggedInUser && showMenu &&
					<menu className="menu-container">
						<div className="menu-inner ">
							<div className="d-table w-100">
								<Menu {...matchProps}/>
							</div>
						</div>
					</menu>}
					<main className="main-content flex-grow-1">
						<div className="main-inner" ref={bankjegyContext.mainInnerRef}>
							{children}
						</div>
					</main>
					<Footer/>
				</div>
			</div>
		</div>
	)
})