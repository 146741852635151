import React, {useContext, useEffect} from 'react'
import {Route, useLocation} from "react-router";
import {BankjegyContext} from "../components/BankjegyContext";
import {DefaultLayoutContent} from "./DefaultLayoutContent";

const DefaultLayout = ({component: Component, ...rest}) => {
	const path = useLocation().pathname
	const bankjegyContext = useContext(BankjegyContext)
	useEffect(()=>{
		bankjegyContext.setLastLocation(path)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [path])
	return (
		<Route  {...rest} render={matchProps => (
			<DefaultLayoutContent styleClass='default-main-layout' >
				<Component {...matchProps} />
			</DefaultLayoutContent>
		)} />
	)
};

export default DefaultLayout