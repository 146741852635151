import React from 'react'
import {listEnumValues} from "../../routes/EnumUtils";
import ComboEditor from "./ComboEditor";

const ComboEnumEditor = ({
	fieldName,
	fieldLabel,
	placeholder,
	enumClass,
	except,
	onChange,
	emptyValue,
	disabled = false,
	readonly = false,
	required
}) => {
  	const possibleEnumValues = listEnumValues(enumClass)
	const possibleValues = emptyValue ? [{ label:'Válasszon!', value:null }] : []
	Object.entries(possibleEnumValues).map((value, idx) => {
		if (!except || !except.includes(value[0])) {
	    	possibleValues.push({ label:value[1], value:value[0] })
	  	}
	  		return null
		})
	return (
		<ComboEditor
			fieldName={fieldName}
			fieldLabel={fieldLabel}
			placeholder={placeholder}
			onChange={onChange}
			enumClass={enumClass}
			except={except}
			emptyValue={emptyValue}
			disabled={disabled}
			readonly={readonly}
			possibleValues={possibleValues}
			required={required}
		/>
	)
}

export default ComboEnumEditor
