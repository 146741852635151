import {ErrorMessage, useField} from 'formik'
import React, {useState} from 'react'
import {Calendar} from 'primereact/calendar'
import moment from 'moment'
import {addLocale} from 'primereact/api'
import {CalendarLocale} from '../../routes/Common'

// graphql-nek  moment(values.date).format() konvertálással kell átadni
// monthNavigator: hónap választó a dropdown-ban
// yearNavigator: év választó a dropdown-ban, kötelező hozzá a yearRange megadása
// yearRange: yearNavigatorral együtt használatos: pl: '2010:2030' formátumban

const DateEditor = ({
	fieldName,
	fieldLabel,
	placeholder,
	disabled = false,
	monthNavigator = false,
	yearNavigator = false,
	yearRange,
	minDate,
	maxDate,
	showTime = false,
	showSeconds = false,
	required = false,
	showButtonBar = false
}) => {
	const [field, meta] = useField(fieldName);
	const [viewDate, setViewDate] = useState(moment().toDate())

	const onDateChange = (e) => {
		field.onChange({target: { value: e.value, name: fieldName}})
	}

	addLocale('hu', CalendarLocale)

	return (<div className=''>
			<div className='form-group w-100'>
				{fieldLabel ? <label className={`${required ? 'required' : ''}`} htmlFor={'input' + field.name}>{fieldLabel}</label> : ''}
				<Calendar
					locale='hu'
					className={`form-control p-0 border-0 ${meta.error && meta.touched ? 'is-invalid' : ''}`}
					id={'input' + field.name}
					name={field.name}
					placeholder={`${placeholder || fieldLabel}${required ? ' *' : ''}`}
					value={field.value}
					onChange={onDateChange}
					monthNavigator={monthNavigator}
					yearNavigator={yearNavigator}
					yearRange={yearRange}
					minDate={minDate}
					maxDate={maxDate}
					onBlur={field.onBlur}
					disabled={disabled}
					dateFormat='yy.mm.dd.'
					showIcon
					showButtonBar={showButtonBar}
					showTime={showTime}
					showSeconds={showSeconds}
					hourFormat="24"
					icon='pi pi-calendar'
					required={required}
					viewDate={viewDate}
					onViewDateChange={(e)=>{
						setViewDate(e.value)
					}}
					// showButtonBar={true}
				/>
				<ErrorMessage name={field.name} component="div" className="error-message"/>
			</div>
		</div>
	)
}

export default DateEditor
