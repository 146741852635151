import {ErrorMessage, useField} from 'formik'
import React from 'react'
import {Editor} from "primereact/editor";

const HtmlEditor = ({
						fieldName,
						fieldLabel,
						className,
						labelClassName,
						placeholder,
						disabled = false,
						focus=false,
						readonly=false,
						required=false,
						header
					}) => {
	const [field, meta] = useField(fieldName);
	const onTextChange = (e) => {
		field.onChange({target: { value: e.htmlValue, name: fieldName}})
	}
	return (
		<div className={`form-group w-100 ${className ? className : ''}`}>
			{fieldLabel ? <label className={`${!readonly && required ? 'required' : ''} ${readonly ? 'col-form-label' : ''} ${labelClassName ? labelClassName : ''}`} htmlFor={'input' + field.name}>{fieldLabel}</label> : ''}
			{readonly ?
				<input type="text" readOnly className="form-control-plaintext" id={'input' + field.name} value={field.value}/> :
				<>
					<Editor
						headerTemplate={header ? header : undefined}
						className={`${className ? className : ''} ${meta.error && meta.touched ? 'is-invalid' : ''}`}
						id={'input' + field.name}
						name={field.name}
						placeholder={`${placeholder || fieldLabel}${(!placeholder && required) ? ' *' : ''}`}
						value={field.value}
						onTextChange={onTextChange}
						onBlur={field.onBlur}
						disabled={disabled}
						required={required}
					/>
					<ErrorMessage name={field.name} component="div" className="error-message"/>
				</>
			}
		</div>
	)}

export default HtmlEditor
