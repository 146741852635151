import React from 'react'
import {useQuery} from '@apollo/client'
import gql from 'graphql-tag'
import {formatCurrency, formatDateTime} from '../Common'
import {LabelValue} from './BankjegyPage'
import {getEnumValue} from '../EnumUtils'

const GET_ARVALTOZAS = gql`
    query GetArvaltozas($bankjegyId: Long!) {
        getArvaltozas(bankjegyId: $bankjegyId){
            tipus
            arak {
                letrehozasIdeje
                ar
            }
        }
    }
`

const ArvaltozasPanel = ({bankjegyId}) => {
	const arvaltozasQuery = useQuery(GET_ARVALTOZAS, {fetchPolicy: 'network-only', variables: {bankjegyId: bankjegyId}})
	return <div className="m-3">
		<h5>Árváltozás</h5>
		<hr/>
		<div className="d-flex flex-wrap bankjegy-desc">
			<div className="col-12 col-lg-6 col-xl-12 pe-lg-2 pe-xl-0">
				{arvaltozasQuery.data?.getArvaltozas?.map((arvaltozas, idx) => {
					return <div key={`arvaltozas_panel_${idx}`}>
						<LabelValue label={getEnumValue('Bankjegy', arvaltozas.tipus.replace('_','@'))}>
							{arvaltozas.arak.map((arElem, idx) => <>
									<div className="text-break" key={`arvaltozas_arak_${idx}`}>
										<span className="me-2 text-nowrap"></span>
										<span className="me-2 text-nowrap">{`${(formatCurrency(arElem.ar, 'Ft'))}`}</span>
										<small className="me-2 text-nowrap fst-italic">{`(${formatDateTime(arElem.letrehozasIdeje)})`}</small>
									</div>
								</>
							)}
						</LabelValue>
					</div>
				})}
			</div>
		</div>
	</div>
}

export default ArvaltozasPanel