import {Password} from 'primereact/password'
import { ErrorMessage, useField } from 'formik'
import React, {useEffect} from 'react'
import $ from "jquery";

const PasswordEditor = ({
                                fieldName,
                                fieldLabel,
                                placeholder,
                                disabled = false,
                                passwordMeter = false,
                                focus=false,
                                required
                              }) => {
  const [field, meta] = useField(fieldName);
  useEffect(()=>{
    if (focus)
    {
      $('#input'+field.name).focus()
    }
  }, [field.name, focus])
  return <div className=''>
    <div className='form-group w-100'>
      {fieldLabel ? <label htmlFor={'input' + field.name}>{fieldLabel}</label> : ''}
      <Password
          // @ts-ignore
          className={`form-control ${meta.error && meta.touched ? 'is-invalid' : ''}`}
          id={'input' + field.name}
          name={field.name}
          placeholder={`${placeholder || fieldLabel}${required ? ' *' : ''}`}
          value={field.value}
          onChange={field.onChange}
          onBlur={field.onBlur}
          disabled={disabled}
          feedback={passwordMeter}
          weakLabel='Gyenge'
          mediumLabel='Közepes'
          strongLabel='Erős'
          promptLabel='Írd be a jelszót!'
          autoComplete="off"
      />
      <ErrorMessage name={field.name} component="div" className="error-message" />
    </div>
  </div>
}

export default PasswordEditor
