import React, {useContext, useEffect} from 'react'
import {Redirect, Route, useLocation} from "react-router";
import {BankjegyLayoutContent} from "./BankjegyLayoutContent";
import {UserContext} from "../components/UserContext";
import {BankjegyContext} from "../components/BankjegyContext";

const BankjegyLayout = ({component: Component, ...rest}) => {
	const userContext = useContext(UserContext);
	const path = useLocation().pathname
	const bankjegyContext = useContext(BankjegyContext)
	useEffect(()=>{
		bankjegyContext.setLastLocation(path)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [path])
	return (
		<Route  {...rest} render={matchProps => userContext.noLoggedInUser === false ? (
			<BankjegyLayoutContent styleClass={rest.styleClass} >
				<Component {...matchProps} />
			</BankjegyLayoutContent>
		) : (userContext.noLoggedInUser === true ? <Redirect
			to={{
				pathname: "/"
			}}
		/> : null)} />
	)
};

export default BankjegyLayout