import {enumValues} from './EnumValues'

const EnumUtils = ({ enumClassName, enumName }) => {
  return getEnumValue(enumClassName, enumName)
}

const NO_DATA = 'N/A'
const getEnumValueInternal = (enumClassName, enumName) => {
  const enumClass = enumValues ? enumValues[enumClassName] : null
  let splitted = enumName?.split('@') || []
  let postfix = splitted.length > 1 ? ` (${splitted[1]})` : ''
  if (enumClass?.[splitted[0]]) {
    return `${enumClass[splitted[0]]}${postfix}`
  }
  // console.log('getEnumValue: ' + enumClassName + ':' + enumName + '=' + retVal)
  return NO_DATA
}

export const getEnumValue = (enumClassName, enumName) => {
  if (enumName instanceof Array) {
    let res = ''
    enumName.forEach((value) => {
      res = res.length > 0 ? res.concat(', ') : ''
      res = res.concat(getEnumValueInternal(enumClassName, value))
    })
    return res
  } else {
    return getEnumValueInternal(enumClassName, enumName)
  }
}

export const getEnumValueOrEnumName = (enumClassName, enumName) => {
  const res = getEnumValue(enumClassName, enumName)
  if (res === NO_DATA) {
    return enumName
  }
  return res
}

export const listEnumValues = (enumClassName) => {
  let retVal = {}
  if (enumValues[enumClassName]) {
    const enumClass = enumValues[enumClassName]
    retVal = enumClass
    // retVal = enumClass ? Object.keys(enumClass).map(k => enumClass[k]) : []
  }
  // console.log('listEnumValues: ' + enumClassName + '=' + JSON.stringify(retVal))
  return retVal
}

export default EnumUtils
