import React, {useContext, useRef, useState} from 'react'
import {Formik} from 'formik'
import {Button} from 'primereact/button'
import {Messages} from 'primereact/messages'
import gql from 'graphql-tag'
import {useMutation, useQuery} from '@apollo/client'
import {createErrorMessage} from '../user/LoginPage'
import {ProgressBar} from 'primereact/progressbar'
import CheckboxEditor from '../../components/editors/CheckboxEditor'
import {withRouter} from 'react-router'
import {SuccessMessagePanel} from '../user/RegistrationPage'
import {UserContext} from '../../components/UserContext'
import TextEditor from '../../components/editors/TextEditor'
import * as Yup from 'yup'
import {setLocale} from 'yup'
import AdminPanel from '../../layout/AdminPanel'
import {Dialog} from 'primereact/dialog'

const SAVE_FELHASZNALO = gql`
    mutation SaveFelhasznalo($felhasznalo: FelhasznaloInput!) {
        saveFelhasznalo(felhasznalo: $felhasznalo) {
            id
            nev
            telefonszam
            gyujto
        }
    }
`

const FIND_FELHASZNALO_BY_ID = gql`
    query FindFelhasznaloById($id: ID!) {
        findFelhasznaloById(id: $id) {
            id
            email
            nev
            telefonszam
            gyujto
            utolsoBelepesIdeje
            regisztracioIdeje
        }
    }
`

const DELETE_OWN_USER_AND_LOGOUT = gql`
    mutation DeleteOwnUserAndLogout($id:ID!) {
        deleteOwnUserAndLogout(id:$id)
    }
`

const SuccessFelhasznaloPanel = () => (
	<SuccessMessagePanel
		title="Sikeres mentés!"
		desc={[<div key="success-save-panel">A felhasznaló adatai sikeresen mentésre kerültek!</div>]}
	/>
)

const UserForm = withRouter(({history}) => {
	const userContext = useContext(UserContext)
	const messageRef = useRef(null)
	const [saveFelhasznalo, saveFelhasznaloResult] = useMutation(SAVE_FELHASZNALO, {
		onCompleted({saveFelhasznalo}) {
			if (saveFelhasznalo) {
				userContext.updateUser().then()
			}
		}
	})

	const userId = userContext.loggedInUser.id
	const queryFelhasznalo = useQuery(FIND_FELHASZNALO_BY_ID, {variables: {id: userId}})
	const [showDeleteUserModel, setShowDeleteUserModel] = useState(false)
	const [showVerificationDeleteModel, setShowVerificationDeleteModel] = useState(false)
	const onModalClose = () => {
		setShowDeleteUserModel(false)
	}

	const [deleteOwnUserAndLogout] = useMutation(DELETE_OWN_USER_AND_LOGOUT, {
		variables: {id: userId},
		onCompleted() {
			userContext.growl({severity: 'info', summary: 'A felhasznaló adatai sikeresen törlésre kerültek! A rendszer kiléptette Önt!'})
			userContext.logoutUser()
			history.push('/')
		}
	})

	/* eslint-disable no-template-curly-in-string */
	setLocale({
		mixed: {
			required: 'A mező kitöltése kötelező!'
		},
		string: {
			max: 'A ${label} mezőnek maximum ${max} karakter hosszúnak lehet lennie!',
			matches: 'Nem megfelelő ${label}! pl.: nev@email.hu'
		}
	})

	const userValidationSchema = Yup.object().shape({
		name: Yup.string().max(255).required().label('név'),
		phoneNumber: Yup.string().matches(/^\+[1-9][0-9]{9,12}$/, 'Nem megfelelő telefonszám! pl.: +36201234567')
	})

	return (
		<div className="mt-2">
			<Dialog
				header='Törlés'
				visible={showDeleteUserModel}
				onHide={onModalClose}>
				<div className="">
					Ha a törlést választja, akkor a felhasználó minden adata törlésre kerül. Mégis folytatja?
					<div className="buttons mt-3">
						<Button label="Igen"
							className="green-button"
							type="button"
							onClick={() => {
								setShowDeleteUserModel(false)
								setShowVerificationDeleteModel(true)
							}}/>
						<Button label="Nem"
							className="brown-button"
							type="button"
							onClick={() => {
								setShowDeleteUserModel(false)
							}}/>
					</div>
				</div>
			</Dialog>
			<Dialog
				header='Törlés'
				visible={showVerificationDeleteModel}
				onHide={onModalClose}>
				<div className="">
					A felhasználó véglegesen törlésre kerül. A művelet végrahajtását követően a rendszer kilépteti az oldalról. Biztosan folytatja?<br/>
					<div className="buttons mt-3">
						<Button label="Igen"
							className="green-button"
							type="button"
							onClick={e => {
								deleteOwnUserAndLogout().then()
							}}/>
						<Button label="Nem"
							className="brown-button"
							type="button"
							onClick={e => {
								setShowVerificationDeleteModel(false)
							}}/>
					</div>
				</div>
			</Dialog>
			{(saveFelhasznaloResult.data || saveFelhasznaloResult.loading || saveFelhasznaloResult.error) ?
				<div>
					{saveFelhasznaloResult.data ?
						<SuccessFelhasznaloPanel/> :
						<>
							{saveFelhasznaloResult.mutationLoading && <ProgressBar className="mt-3" mode="indeterminate"/>}
							{createErrorMessage(saveFelhasznaloResult.mutationError, 'Sikertelen mentés!', messageRef, 20000)}
							<Messages className="mt-3" ref={messageRef}/>
						</>}
					<div className="row top10">
						<div className="col-12 col-md-12">
							<Button
								label="Bezárás"
								onClick={() => {
									history.push('/')
								}}
							/>
						</div>
					</div>
				</div>
				:
				queryFelhasznalo.error ?
					<>
						{messageRef.current && messageRef.current.show({severity: 'error', summary: '', detail: 'A felhasznaló oldal nem tölthető be!', closable: false, life: 120000})}
						<Messages className="mt-3" ref={messageRef}/>
					</> :
					<>
						{(queryFelhasznalo.data && queryFelhasznalo.data.findFelhasznaloById) &&
						<Formik
							initialValues={{
								email: queryFelhasznalo.data.findFelhasznaloById.email,
								name: queryFelhasznalo.data.findFelhasznaloById.nev,
								phoneNumber: queryFelhasznalo.data.findFelhasznaloById.telefonszam || '',
								gyujto: queryFelhasznalo.data.findFelhasznaloById.gyujto
							}}
							validationSchema={userValidationSchema}
							onSubmit={async (values, {setSubmitting}) => {
								await saveFelhasznalo({
									variables: {
										'felhasznalo': {
											'id': userId,
											'nev': values.name,
											'telefonszam': values.phoneNumber,
											'gyujto': values.gyujto
										}
									}
								}).then(userContext.updateUser)
							}}
						>
							{({
								handleSubmit
							}) => (
								<form className="form-user"
									onSubmit={handleSubmit}
								>
									<div className="mb-3">
										<TextEditor readonly fieldName="email"/>
									</div>
									<div className="mb-3">
										<TextEditor focus fieldName="name" placeholder="név"/>
									</div>
									<div className="mb-3">
										<TextEditor fieldName="phoneNumber" placeholder="telefonszám"/>
									</div>
									<div className="mb-3">
										<CheckboxEditor fieldName="gyujto" fieldLabel="Gyűjtői felületek megjelenítése"/>
									</div>
									<div className="buttons">
										<Button
											disabled={saveFelhasznaloResult.mutationLoading}
											className="green-button"
											label="Mentés"
											type="submit"/>
										{userContext.loggedInUser.szerep !== 'ADMIN' && <Button
											className="red-button ml-1"
											label="Felhasználó törlése"
											type="button"
											onClick={e => {
												setShowDeleteUserModel(true)
											}}/>}
										<Button
											onClick={(e) => {
												history.push('/')
											}}
											className="brown-button ml-1"
											label="Mégsem"
											type="button"/>
									</div>
									{saveFelhasznaloResult.mutationLoading && <ProgressBar className="mt-3" mode="indeterminate"/>}
									{createErrorMessage(saveFelhasznaloResult.mutationError, 'Sikertelen mentés!', messageRef, 20000)}
									<Messages className="mt-3" ref={messageRef}/>
								</form>
							)}
						</Formik>
						}
					</>
			}

		</div>
	)
})

const User = () => {
	return <AdminPanel label="Saját adatok">
		<UserForm/>
	</AdminPanel>
}

export default User
