import React, {useRef, useState} from 'react'
import {ErrorMessage, Field, useField} from "formik";
import imgMoney from "../../images/money-128.png";
import $ from "jquery"
import imgDelete16 from "../../images/trash-w-16.png";

//a setFieldValue-t az alábbiak szerint kell paraméterezni:
//onChange((e)=>(setFieldValue(fieldName.toString(), (e.target.files && e.target.files.item(0)))))
//az accept-et pedig pl így:
//accept='.csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
const FileUploadEditor = (
	{
		fieldName,
		fieldLabel,
		previewLabel,
		className,
		labelClassName,
		children,
		readonly=false,
		accept,
		required

	}) => {
	const [field, meta] = useField(fieldName);
	const [preview, setPreview] = useState(false)
	const readURL = (input) => {
		if (input.files && input.files[0]) {
			let reader = new FileReader();
			reader.onload = (e) => {
				$('#' + fieldName + '_previewImage').attr('src', e.target.result);
			};
			reader.readAsDataURL(input.files[0]);
			setPreview(true)
		}
	}

	const fileRef = useRef(null)


	const onFileChange = (e) => {
		field.onChange({target: { value: (e.target.files && e.target.files.item(0)), name: fieldName}})
		readURL(e.target)
	}
	return (
		<div className={`form-group p-0 border-0 file-upload ${className ? className : ''}`}>
			{fieldLabel ? <label className={`${(!readonly && required) ? 'required' : ''} ${labelClassName ? labelClassName : ''}`} htmlFor={'input' + field.name}>{fieldLabel}</label> : ''}
			{readonly ?
				<input type="text" readOnly className="form-control-plaintext" id={'input' + field.name} value={field.value}/> :
				<>
					{children}
					{preview && <>
						{previewLabel && <label>{previewLabel}</label>}
						<div className={`img-wrapper flex-grow-1 text-center mb-2`}>
							<img id={`${fieldName}_previewImage`} src={imgMoney} alt="preview"/>
						</div>
					</>}
					<div className="row">
						<div className="col">
							<Field id={'input' + field.name} name={`${field.name}_name`}
								render={({ field }) => (
									<input
										ref={fileRef}
										id={'input_file_' + field.name}
										className={`${className ? className : ''} form-control ${meta.error && meta.touched ? 'is-invalid' : ''}`}
										type='file'
										accept={accept}
										onChange={onFileChange}
										required={required}
										/>
								)}>
							</Field>
						</div>
						{preview && <div className="col-auto">
							<button className="red-button ml-1 cursor-pointer" onClick={() => {
								setPreview(false)
								field.onChange({target: { value: null, name: fieldName}})
								fileRef && (fileRef.current.value = null)
							}}>
								<div className="d-inline-flex align-items-center">
									<img src={imgDelete16} alt="delete preview"/>
								</div>
							</button>
						</div>}
					</div>
					<ErrorMessage name={`${field.name}_name`} component="div" className="error-message"/>
				</>
			}
		</div>
	)
}

export default FileUploadEditor
