import React, {useEffect, useMemo, useRef, useState} from 'react'
import {ErrorMessage, useField} from 'formik'
import {InputNumber} from 'primereact/inputnumber'
import {InputGroupWrapper} from './TextEditor'
import numeral from 'numeral'

const NumberEditor = (
	{
		fieldName,
		fieldLabel,
		className,
		labelClassName,
		placeholder,
		disabled = false,
		children,
		focus = false,
		readonly = false,
		required,
		inputGroupBefore,
		inputGroupAfter,
		useGrouping = false,
		showButtons,
		readonlyValueFormatter,
		decimals,
		min,
		max,
		numbers,
		readonlyLabelClassName = 'col-5',
		readonlyValueClassName = 'col-7'
	}) => {
	const [field, meta, helper] = useField(fieldName)
	const numberRef = useRef(null)
	const [readonlyValue, setReadonlyValue] = useState('')

	useEffect(() => {
		if (focus && numberRef?.current) {
			numberRef.current.focus()
		}
		// eslint-disable-next-line
	}, [focus, numberRef?.current])

	const readonlyValueFormatterFunc = (value) => {
		if (readonlyValueFormatter) {
			return readonlyValueFormatter(value)
		}
		if (value || value === 0) {
			let unit = ''
			if (inputGroupAfter) {
				unit = ' ' + inputGroupAfter
			}
			return value + unit
		}
	}

	useMemo(() => {
		if (readonly) {
			let val = field.value
			let res = ''
			if (readonlyValueFormatter) {
				res = readonlyValueFormatter(val)
			} else {
				res = readonlyValueFormatterFunc(val)
			}
			setReadonlyValue(res)
		}
		// eslint-disable-next-line
	}, [field.value, readonly])

	const onInputTextBlur = (e) => {
		let val
		if (e.target.value && (min > numeral(e.target.value)._value)) {
			val = min
			numberRef.current.lastValue = min
			numberRef.current.value = min
		} else {
			val = numeral(e.target.value).value()
		}
		helper.setValue(val)
	}
	const onInputTextChange = (e) => {
		let val
		if (max < numeral(e.value)._value) {
			val = max
			numberRef.current.lastValue = max
			numberRef.current.value = max
		} else if (max === 0 && e.value > 0) {
			val = null
		} else if (min >= 0 || e.originalEvent.key !== '-') {
			val = e.value
		}
		helper.setValue(val)
	}

	return <div className={`form-group w-100 ${readonly ? 'row' : ''} ${className ? className : ''}`}>
		{fieldLabel ? <label
			className={`${!readonly && required ? 'required' : ''} ${readonly && (readonlyLabelClassName ?? '')} ${readonly ? 'col-form-label' : ''} ${labelClassName ? labelClassName : ''}`}
			htmlFor={'input' + field.name}>{fieldLabel}</label> : ''}
		{readonly ?
			<div className={readonlyValueClassName ?? ''}>
				<input type="text" readOnly className="form-control-plaintext fw-bold" id={'input' + field.name} value={readonlyValue}/>
			</div> :
			<>
				<InputGroupWrapper inputGroupAfter={inputGroupAfter} inputGroupBefore={inputGroupBefore}>
					<InputNumber
						inputRef={numberRef}
						className={`${className ? className : ''} form-control ${meta.error && meta.touched ? 'is-invalid' : ''}`}
						id={'input' + field.name}
						autoFocus={focus}
						name={field.name}
						placeholder={placeholder}
						onChange={onInputTextChange}
						value={field.value}
						onBlur={onInputTextBlur}
						disabled={disabled}
						minFractionDigits={decimals > 0 ? 1 : 0}
						maxFractionDigits={decimals}
						useGrouping={useGrouping}
						min={(min >= 0) ? 0 : null}
						max={max ? max : (numbers ? parseInt(new Array(numbers + 1).join('9')) : null)} //ha numbers-t adunk meg akkor generálunk egy max értéket.
						required={false}
						showButtons={showButtons}
					/>
				</InputGroupWrapper>
				<ErrorMessage name={field.name} component="div" className="error-message"/>
			</>
		}
		{children}
	</div>
}

export default NumberEditor
