import {ErrorMessage, useField} from 'formik'
import React, {useEffect, useMemo, useState} from 'react'
import {AutoComplete} from "primereact/autocomplete";

const AutoCompleteEditor = ({
						 fieldName,
						 fieldLabel,
						 placeholder,
						 emptyValue,
						 onChange,
						 disabled = false,
						 readonly = false,
						 possibleValues,
						 queryMethod,
						 required,
						 multiple = false,
						 dropdown = false
					 }) => {
	const [field, meta] = useField(fieldName);
	const [suggestions, setSuggestions] = useState(null)
	const [selectedValue, setSelectedValue] = useState(null)
	const [selectedValues, setSelectedValues] = useState([])
	const [readonlyValue, setReadonlyValue] = useState(null)
	useEffect(()=>{
		//reset
		if (!field.value)
		{
			setSelectedValue(null)
			setSelectedValues([])
		}
	}, [field.value])
	useMemo(()=>{

		if (multiple) {
			const selected = possibleValues?.filter(f => f.value === field.value)?.map(m => m.label) || field.value
			setSelectedValues(selected)
		} else {
			const selected = possibleValues?.find(f => f.value === field.value)?.label || field.value
			setSelectedValue(selected)
		}
		if (readonly) {
			if (multiple) {
				let listValue = ''
				if (field.value && field.value.length > 0) {
					let indent = ''
					field.value.forEach((v) => {
						listValue += indent + v
						indent = ', '
					})
				}
				setReadonlyValue(listValue)
			}
			else {
				setReadonlyValue(field.value)
			}
		}
		// eslint-disable-next-line
	},[readonly, possibleValues])
	const onAutoCompleteChange = (e) => {
		if (onChange)
		{
			onChange(e.value)
			field.onChange({target: { value: e.value, name: fieldName}})
		} else {
			if (multiple) {
				let resLabel = []
				let resValue = []
				e.value.map(s => {
					return possibleValues.forEach((val)=>{
						if (val.label === s) {
							resLabel.push(val.label)
							resValue.push(val.value)
						}
					})
				})
				setSelectedValues(resLabel)
				field.onChange({target: { value: resValue, name: fieldName}})
			} else {
				const value = possibleValues.find(f=>f.label === e.value)
				setSelectedValue(e.value)
				field.onChange({target: { value: (value ? value.value : (e.value ? e.value : null)), name: fieldName}})
			}
		}
	}

	const completeMethod = (e) => {
		let results = possibleValues.filter((pv) => {
			if (pv.value) {
				return queryMethod(e, pv.value)
			}
			return null;
		});
		setSuggestions(results.map((a, i) => {
			return a.label
		}))
	}
	return (
		<div className=''>
			<div className='form-group w-100'>
				{fieldLabel ? <label className={`${!readonly && required ? 'required' : ''} ${readonly ? 'col-form-label' : ''}`} htmlFor={'input' + field.name}>{fieldLabel}</label> : ''}
				{readonly ?
					<input type="text" readOnly className="form-control-plaintext" id={'input' + field.name} value={readonlyValue }/> :
					<>
						<AutoComplete
							className={`form-control w-100 d-flex ${field.value && field.value.length > 0 ? 'filled' : ''} ${meta.error && meta.touched ? 'is-invalid' : ''}`}
							id={'input' + field.name}
							placeholder={`${placeholder || fieldLabel}${(!placeholder && required) ? ' *' : ''}`}
							value={multiple ? selectedValues : selectedValue}
							onChange={onAutoCompleteChange}
							suggestions={suggestions}
							completeMethod={completeMethod}
							disabled={disabled}
							required={required}
							multiple={multiple}
							dropdown={dropdown}
							valueTemplate={e=>e.label}
						/>
						<ErrorMessage name={field.name} component="div" className="error-message"/>
					</>
				}
			</div>
		</div>
	)
}

export default AutoCompleteEditor
