import React, {useContext, useEffect, useRef, useState} from 'react'
import Sticky from 'react-sticky-el'
import {UserContext} from '../../components/UserContext'
import {useHistory} from 'react-router'
import {getEnumValue} from '../EnumUtils'
import useViewport from '../useViewport'
import gql from 'graphql-tag'
import {useQuery} from '@apollo/client'
import {Button} from 'primereact/button'
import {Menu} from 'primereact/menu'

export const RendszerInfo = () => {
	const GET_RENDSZERINFO = gql`
        query GetRendszerInfo{
            getRendszerInfo {
                countFelhasznalo
                countBejelentkezes
            }
        }
	`
	const rendszerInfoQuery = useQuery(GET_RENDSZERINFO)
	return <>
		{rendszerInfoQuery && rendszerInfoQuery.data &&
		<div className="rendszer-info d-lg-flex mb-2 mb-md-0 font-size flex-wrap flex-lg-nowrap justify-content-end justify-content-md-start">
			<div className="col-auto me-md-3 px-0 text-end"><span  title="Eddigi regisztráltak száma" className="title">Regisztráltak:</span> <span>{rendszerInfoQuery.data.getRendszerInfo.countFelhasznalo}</span></div>
			<div className="col px-0 text-end text-md-start" ><span title="Eddigi belépések száma" className="title">Belépések:</span> <span>{rendszerInfoQuery.data.getRendszerInfo.countBejelentkezes}</span></div>
		</div>}
	</>
}
const UserHeaderPanelContent = ({props}) => {
	const className = props.className
	const theme = props.theme
	const logo = props.logo
	const userContext = useContext(UserContext)
	const menuRef = useRef(null)
	const history = useHistory()
	const [items, setItems] = useState([])
	useEffect(()=>{
		let subItems = []
		subItems.push({label: 'Nyitólap', command:()=>{ history.push({pathname: '/'})}})
		subItems.push({label: 'Saját adatok', command:()=>{ history.push({pathname: '/sajatadatok'})}})
		if (userContext.loggedInUser.szerep === 'ADMIN') {
			subItems.push({label: 'Felhasználók', command:()=>{ history.push({pathname: '/felhasznalok'})}})
		}
		subItems.push({label: 'Jelszó módosítása', command:()=>{ history.push({pathname: '/jelszomodositas'})}})
		if (userContext.loggedInUser.gyujto) {
			subItems.push({label: 'Gyűjtői statisztika', command:()=>{ history.push({pathname: '/gyujtoistatisztika'})}})
		}
		if (userContext.loggedInUser.szerep === 'ADMIN' && userContext.loggedInUser.superuser) {
			subItems.push({label: 'Megjegyzés statisztika', command:()=>{ history.push({pathname: '/megjegyzesstatisztika'})}})
		}
		if (userContext.loggedInUser.szerep === 'ADMIN' && userContext.loggedInUser.superuser) {
			subItems.push({label: 'Árváltozás statisztika', command:()=>{ history.push({pathname: '/arvaltozasstatisztika'})}})
		}
		if (userContext.loggedInUser.szerep === 'ADMIN' && userContext.loggedInUser.superuser) {
			subItems.push({label: 'Gyűjteményem', command:()=>{ history.push({pathname: '/gyujtemenyem'})}})
		}
		if (userContext.loggedInUser.szerep === 'ADMIN') {
			subItems.push({label: 'Üzenetküldés', command:()=>{ history.push({pathname: '/levelkuldes'})}})
		}
		if (userContext.loggedInUser.szerep === 'GYUJTO') {
			subItems.push({label: 'Kapcsolat', command:()=>{ history.push({pathname: '/kapcsolat'})}})
		}
		if (userContext.loggedInUser.szerep === 'ADMIN') {
			subItems.push({label: 'Napló', command:()=>{ history.push({pathname: '/naplo'})}})
		}

		setItems([
			{
				items: subItems,
			},
			{
				items: [{label: 'Kijelentkezés', command:()=>{
						userContext.logoutUser()
						history.push('/')
					}}]
			}
		])
		// eslint-disable-next-line
	}, [])
	return <header>
		<div className={`${className ? className : ''} ${theme === 'white' ? 'theme-white' : (theme === 'black' ? 'theme-black' : 'theme-menu')}`}>
			<div className="d-md-flex w-100">
				<div className="col px-md-3">
					<RendszerInfo/>
					{logo}
				</div>
				<div className="col-auto px-0 px-md-3">
					<div className="d-flex flex-column flex-md-row align-items-md-center">
						<div className="order-1 order-md-0 mt-1 mt-md-0">
							<span>{userContext.loggedInUser.nev}</span><span> ({getEnumValue('Szerep', userContext.loggedInUser.szerep) })</span>
						</div>
						<div className="user-menu order-0 order-md-1 pr-3">
							<Menu model={items} popup ref={menuRef}/>
							<Button className='ms-md-2' id='menuDropdown' label="Menü" icon="pi pi-th-large" onClick={(event) => menuRef.current.toggle(event)}/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</header>
}

const UserHeaderPanel = (props) => {
	const userContext = useContext(UserContext)
	const viewport = useViewport()
	return (userContext.loggedInUser &&
		<div className="user-panel mt-md-2">
			{(['md', 'lg', 'xl'].includes(viewport) && document.getElementsByClassName("main-inner") > 0) ? <Sticky scrollElement=".main-inner" boundaryElement=".block" style = {{position: 'relative'}} hideOnBoundaryHit={false}>
				<UserHeaderPanelContent props={props}/>
			</Sticky> : <UserHeaderPanelContent props={props}/>}
		</div>
	)
}

export default UserHeaderPanel
