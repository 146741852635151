import React, {useContext, useRef} from 'react'
import {Formik} from 'formik'
import {Button} from 'primereact/button'
import {Messages} from 'primereact/messages'
import gql from 'graphql-tag'
import {useMutation, useQuery} from '@apollo/client'
import {createErrorMessage} from './LoginPage'
import {ProgressBar} from 'primereact/progressbar'
import imgTick from '../../images/tick-32.png'
import TextEditor from '../../components/editors/TextEditor'
import PasswordEditor from '../../components/editors/PasswordEditor'
import ChaptchaEditor from '../../components/editors/ChaptchaEditor'
import {UserContext} from '../../components/UserContext'
import {Logo} from '../../layout/Menu'
import {Link, Redirect} from 'react-router-dom'
import {Panel} from 'primereact/panel'
import * as Yup from 'yup'
import {setLocale} from 'yup'

const REGISTRATION = gql`
    mutation Registration($regisztracio: Regisztracio!) {
        registration(regisztracio: $regisztracio)
    }
`

const GET_SITE_KEY = gql`
    query GetSiteKey {
        getSiteKey
    }
`

const SuccessRegistrationPanel = () => (
	<>
		<SuccessMessagePanel
			title="Sikeres regisztráció!"
			desc="Köszönjük, hogy regisztrált a weboldalunkon! A megadott e-mail címre hamarosan kapni fog egy levelet, mely tartalmazza az aktiváló linket a regisztráció befejezéséhez."
		/>
		<div className="mt-3 mb-2 text-center">
			<Link to="/">Vissza a nyilólapra</Link>
		</div>
	</>

)

export const SuccessMessagePanel = ({title, desc}) => (
	<div className="success-message">
		<div className="d-flex align-items-center">
			<div className="col-auto"><img src={imgTick} alt="tick"/></div>
			<div className="col">
				<span className="font-weight-bold">{title}</span><br/>
			</div>
		</div>
		<div className="my-3 desc">{desc}</div>
	</div>
)

const RegistrationForm = () => {
	const messageRef = useRef(null)
	const [registration, {data, loading: mutationLoading, error: mutationError}] = useMutation(REGISTRATION)
	const querySiteKey = useQuery(GET_SITE_KEY)
	const initialValues = {email: '', password: '', password2: '', name: '', phoneNumber: ''}

	/* eslint-disable no-template-curly-in-string */
	setLocale({
		mixed: {
			required: 'A mező kitöltése kötelező!'
		},
		string: {
			min: 'A ${label} mezőnek minimum ${min} karakter hosszúnak kell lennie!',
			max: 'A ${label} mezőnek maximum ${max} karakter hosszúnak lehet lennie!',
			matches: 'Nem megfelelő ${label}! pl.: nev@email.hu'
		}
	})

	const registrationValidationSchema = Yup.object().shape({
		email: Yup.string().max(255).matches(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i).required().label('email cím'),
		password: Yup.string().min(8).max(255).required().label('jelszó'),
		password2: Yup.string().required().oneOf([Yup.ref('password'), null], 'Nem egyeznek a beírt jelszavak!').label('jelszó'),
		name: Yup.string().max(255).required().label('név'),
		phoneNumber: Yup.string().matches(/^\+[1-9][0-9]{9,12}$/, 'Nem megfelelő telefonszám! pl.: +36201234567'),
		reCaptcha: Yup.string().required()
	})

	const submit = (values) => {
		registration({
			variables: {
				'regisztracio': {
					'email': values.email,
					'jelszo1': values.password,
					'jelszo2': values.password2,
					'nev': values.name,
					'telefonszam': values.phoneNumber
				}
			}
		})
			.then()
			.catch(e => {
				createErrorMessage(e, 'Sikertelen regisztráció!', messageRef, 20000)
			})
	}

	return (
		<div className="mt-2">
			{querySiteKey.error ?
				<>
					{messageRef.current && messageRef.current.show({severity: 'error', summary: '', detail: 'A regisztrációs oldal nem tölthető be!', closable: false, life: 120000})}
					<Messages className="mt-3" ref={messageRef}/>
				</> :
				querySiteKey.loading ?
					<div>Oldal betöltés...</div> :
					<>
						{(data && data.registration === 'SUCCESS') ?
							<SuccessRegistrationPanel/>
							:
							<>
								<Formik
									validateOnBlur={false}
									validationSchema={registrationValidationSchema}
									initialValues={initialValues}
									onSubmit={submit}
								>
									{({handleSubmit}) => (
										<form className="form-registration" onSubmit={handleSubmit}>
											<div className="mb-3">
												<TextEditor required focus fieldName="email" placeholder="e-mail cím"/>
											</div>

											<div className="mb-3">
												<PasswordEditor required fieldName="password" placeholder="jelszó"/>
											</div>
											<div className="mb-3">
												<PasswordEditor required fieldName="password2" placeholder="jelszó ismét"/>
											</div>
											<div className="mb-3">
												<TextEditor required fieldName="name" placeholder="név"/>
											</div>
											<div className="mb-3">
												<TextEditor fieldName="phoneNumber" placeholder="telefonszám"/>
											</div>
											<ChaptchaEditor fieldName="reCaptcha" siteKey={querySiteKey.data.getSiteKey}/>
											<div>
												<Button
													disabled={/*Object.keys(errors).length > 0 ||*/ mutationLoading}
													className=""
													label="Regisztráció"
													type="submit"
												/>
											</div>
											{mutationLoading && <ProgressBar className="mt-3" mode="indeterminate"/>}
											{createErrorMessage(mutationError, 'Sikertelen regisztráció!', messageRef, 20000)}
											<Messages className="mb-3 text-center" ref={messageRef}/>
										</form>
									)}
								</Formik>
								<div className="row mb-2 mt-3">
									<div className="col-12 col-md-12 cursor-pointer text-center">
										<Link to="/bejelentkezes">Bejelentkezés</Link>
									</div>
								</div>
							</>
						}
					</>
			}

		</div>
	)
}

const RegistrationPage = () => {
	const userContext = useContext(UserContext)
	return (
		<>
			{userContext.loggedInUser ? <Redirect to="/"/> :
				<div className="main-page-content registration-panel">
					<div className="d-flex h-100 justify-content-center flex-column">
						<div className="logo-wrapper d-flex justify-content-center">
							<div className="d-flex flex-column align-self-center">
								<div className="col"><Logo/></div>
								<div className="col mt-5">
									<div>
										<Panel>
											<RegistrationForm/>
										</Panel>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			}
		</>
	)
}

export default RegistrationPage
