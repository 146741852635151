import React, {useContext} from 'react'
import {Logo} from "../../layout/Menu";
import {UserContext} from "../../components/UserContext";
import UserHeaderPanel from "../user/UserHeaderPanel";
import {Redirect} from "react-router";
import useViewport from "../useViewport";
import {ProgressBar} from "primereact/progressbar";

const MainPage = () => {
	const userContext = useContext(UserContext)
	const viewport = useViewport()
	return (
		<>
			{/* FONTOS!!! noLoggedInUser: 3 állapotot kell vizsgálni: */}
			{/*1) null: nem tudjuk, hogy van-e bejelentkezett felhasználó*/}
			{/*2) true: nincs bejelentkezett felhasználó*/}
			{/*3) false: van bejelentkezett felhasználó*/}
			{userContext.noLoggedInUser === null &&
				<div className='d-flex align-items-center'>
					<ProgressBar mode='indeterminate'/>
				</div>}
			{userContext.noLoggedInUser === true && <Redirect to="/bejelentkezes"/>}
			{userContext.noLoggedInUser === false &&
				<div className="main-page-content">
					<div className="d-flex h-100 justify-content-md-center flex-column">
						{['md', 'lg', 'xl'].includes(viewport) && <UserHeaderPanel className="py-2" theme="black"/>}
						<div className="logo-wrapper d-flex justify-content-center">
							<div className="d-flex flex-column align-self-center">
								<div className="col"><Logo/></div>
							</div>
						</div>
					</div>
				</div>
			}
		</>
	)
}

export default MainPage