import React, {useContext, useState} from 'react'
import {Logo} from '../../layout/Menu'
import UserHeaderPanel from '../user/UserHeaderPanel'
import {Button} from 'primereact/button'
import {Formik} from 'formik'
import {UserContext} from '../../components/UserContext'
import * as Yup from 'yup'
import {setLocale} from 'yup'
import gql from 'graphql-tag'
import {useMutation} from '@apollo/client'
import {Prompt, useHistory} from 'react-router'
import HtmlEditor from '../../components/editors/HtmlEditor'
import TextEditor from '../../components/editors/TextEditor'
import {createErrorGrowl} from '../user/LoginPage'
import {Dialog} from 'primereact/dialog'

const SEND_CONTACT_EMAIL = gql`
    mutation SendContactEmail($kapcsolatEmail: KapcsolatEmail!) {
    	sendContactEmail(kapcsolatEmail: $kapcsolatEmail)
    }
`

const ContactPage = () => {
	const userContext = useContext(UserContext)
	const history = useHistory()
	const logo = <Logo/>

	const initialValues = {
		subject: '',
		body: null,
	};

	setLocale({
		mixed: {
			required: 'A mező kitöltése kötelező!',
		}
	})

	const emailValidationSchema = Yup.object().shape({
		subject: Yup.mixed().required(),
		body: Yup.mixed().required()
	})


	const [sendContactEmailMutation] = useMutation(SEND_CONTACT_EMAIL, {
		onCompleted(data)
		{
			userContext.growl({severity: 'info', summary: 'Az üzenet elküldése sikeresen megtörtént!'})
		},
		onError(error)
		{
			createErrorGrowl(error, 'Nem sikerült az üzenetet elküldeni!', userContext, 20000)
		}
	})

	const submit = async (values, {setSubmitting}) => {
		setShowModel(true)
	}

	const SendContactEmailModal = ({values, resetForm}) => {
		return <Dialog
			header='Üzenet'
			visible={showModel}
			onHide={onModalClose}>
			<div className="">
				<div>
					Biztos, hogy el akarja küldeni az üzenetet? <br/>
				</div>
				<div className="buttons mt-3">
					<Button label='Igen'
							type='button'
							className='green-button'
							onClick={() => {
								sendContactEmailMutation({
									variables: {
										kapcsolatEmail: {
											felhasznaloId: userContext.loggedInUser.id,
											targy: values.subject,
											uzenet: values.body
										}
									}
								}).then(() => {
										resetForm({})
									}
								)
								setShowModel(false)
							}}/>
					<Button label='Nem'
							className='brown-button'
							type='button'
							onClick={e => {
								setShowModel(false)
							}}/>
				</div>
			</div>
		</Dialog>
	}

	const htmlEditorHeader = (
		<>
			{/*configuration: https://quilljs.com/docs/modules/toolbar/#container*/}
			<span className="ql-formats">
				<button className="ql-bold" aria-label="Bold"></button>
				<button className="ql-italic" aria-label="Italic"></button>
				<button className="ql-underline" aria-label="Underline"></button>
			</span>
			<span className="ql-formats">
				<select className="ql-size" defaultValue={''}>
					<option value="small"></option>
					<option value={''}></option>
					<option value="large"></option>
					<option value="huge"></option>
				  </select>
			</span>
			<span className="ql-formats">
				<select className="ql-align" defaultValue={''}>
					<option value="center"></option>
					<option value={''}></option>
					<option value="justify"></option>
					<option value="right"></option>
				</select>
			</span>
			<span className="ql-formats">
				<button className="ql-list" aria-label="Unordered" value="bullet"></button>
 				<button className="ql-list" aria-label="Ordered" value="ordered"></button>
			</span>
			{/*<span className="ql-formats">*/}
			{/*	<button className="ql-link" aria-label="Insdrt Link"></button>*/}
			{/*<button className="ql-script" value="sub"></button>*/}
			{/*<button className="ql-script" value="super"></button>*/}
			{/*</span>*/}
		</>
	);

	const [showModel, setShowModel] = useState(false)
	const onModalClose = () => {
		setShowModel(false)
	};

	return (
		<div className="users-page-content main-page-content mb-3">
			<div className="d-flex h-100 flex-column">
				<UserHeaderPanel theme="black" logo={logo}/>
				<div className="container-fluid mt-5">
					<div className="d-flex flex-column align-self-center w-100 py-3">
						<div className="col">
							<h1 className="mb-4">Kapcsolat</h1>
						</div>
						{userContext.loggedInUser.szerep !== 'GYUJTO' ?
							<div className="alert alert-warning mx-3" role="alert">Nincs megfelelő jogosultsága!</div> :
							<>
								<Formik
									initialValues={initialValues}
									validationSchema={emailValidationSchema}
									onSubmit={submit}
								>
									{({handleSubmit, values, resetForm, setFieldValue}) => {
										return <form className='email-form mb-4'
													 onSubmit={handleSubmit}
										>
											<Prompt
												when={JSON.stringify(values) !== JSON.stringify(initialValues)}
												message='El nem mentett adatok vannak az oldalon. Biztosan elhagyja az oldalt?'
											/>
											<SendContactEmailModal values={values} resetForm={resetForm}/>
											<div className="container-fluid">
												<div className="row">
													<div className="col-12">
														<TextEditor fieldLabel='Tárgy' fieldName='subject' required placeholder="Tárgy"/>
													</div>
													<div className="col-12">
														<HtmlEditor fieldLabel='Üzenet' placeholder="Üzenet" fieldName='body' header={htmlEditorHeader}
																	required/>
													</div>
												</div>
												<div className='row'>
													<div className='col-12 col-md-12 align-items-end'>
														<div className="d-flex">
															<Button
																className="green-button mr-1"
																label='Küldés'
																type='submit'/>
															<Button
																onClick={(e) => {
																	history.push('/')
																}}
																className="brown-button"
																label='Mégsem'
																type='button'/>
														</div>
													</div>
												</div>
											</div>
										</form>
									}}
								</Formik>
							</>}
					</div>
				</div>
			</div>
		</div>
	)
}

export default ContactPage
