import React from 'react'

const MultiEditor = ({
	editor1,
	sep='-',
	editor2,
	fieldLabel,
	readonly,
	required
					 }) => {
	return (
		<div className='form-group w-100'>
			{fieldLabel ? <label className={`${!readonly && required ? 'required' : ''} ${readonly ? 'col-form-label' : ''}`}>{fieldLabel}</label> : ''}
			<div className="d-flex align-items-start mx-0 ">
				<div className="col px-0">
					{editor1}
				</div>
				<div className="col-auto px-0">
					<div className="form-group mx-1 mt-1">
						{sep}
					</div>
				</div>
				<div className="col px-0">
					{editor2}
				</div>
			</div>
		</div>

	)
}

export default MultiEditor