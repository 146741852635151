import React, {useContext, useEffect, useRef, useState} from 'react'
import gql from 'graphql-tag'
import {useQuery} from '@apollo/client'
import {UserContext} from '../../components/UserContext'
import {TreeTable} from 'primereact/treetable'
import {Column} from 'primereact/column'
import {getEnumValue} from '../EnumUtils'
import {formatCurrency, formatPiece} from '../Common'
import {ProgressBar} from 'primereact/progressbar'
import {Button} from 'primereact/button'
import {useHistory} from 'react-router'
import AdminPanel from '../../layout/AdminPanel'
import {useDownloadExcel} from 'react-export-table-to-excel'
import moment from 'moment'

export const LIST_BANKJEGY_OSSZESITES = gql`
    query ListBankjegyOsszesites($felhasznaloId: Long!) {
        listBankjegyOsszesites(felhasznaloId: $felhasznaloId) {
            osszesitesSzint
            penznem
            sorozatDto {
                sorrend
                nev
            }
            darabszamUnc
            darabszamEf
            darabszamVf
            ertekUnc
            ertekEf
            ertekVf
        }
    }
`

const BankjegyOsszesitesPage = () => {
	const userContext = useContext(UserContext)
	const history = useHistory()
	const queryListBankjegyOsszesites = useQuery(LIST_BANKJEGY_OSSZESITES, {variables: {felhasznaloId: userContext.loggedInUser.id}, fetchPolicy: 'network-only'})
	const [treeItems, setTreeItems] = useState(null)
	const [expandedKeys, setExpandedKeys] = useState(Object.create([]))

	useEffect(() => {
		let treeNodes = []
		if (queryListBankjegyOsszesites && queryListBankjegyOsszesites.data) {

			queryListBankjegyOsszesites.data.listBankjegyOsszesites.forEach((value, index) => {
				if (value.osszesitesSzint === 'TELJES') {
					let rootItem = ({
						key: index + '-0',
						data: {
							penznem: 'Összes',
							darabszamUnc: formatPiece(value.darabszamUnc),
							ertekUnc: formatCurrency(value.ertekUnc),
							darabszamEf: formatPiece(value.darabszamEf),
							ertekEf: formatCurrency(value.ertekEf),
							darabszamVf: formatPiece(value.darabszamVf),
							ertekVf: formatCurrency(value.ertekVf),
							ertekOssz: formatCurrency(value.ertekUnc + value.ertekEf + value.ertekVf),
							osszesitesSzint: value.osszesitesSzint

						},
						children: []
					})
					if (expandedKeys[rootItem.key]) {
						delete expandedKeys[rootItem.key]
					} else {
						expandedKeys[rootItem.key] = true
					}
					setExpandedKeys(expandedKeys)
					treeNodes = [...treeNodes, rootItem]
				} else if (value.osszesitesSzint === 'PENZNEM') {
					let penznemItem = ({
						key: index + '-1',
						data: {
							penznem: getEnumValue('Penznem', value.penznem),
							sorozat: 'Összes',
							darabszamUnc: formatPiece(value.darabszamUnc),
							ertekUnc: formatCurrency(value.ertekUnc),
							darabszamEf: formatPiece(value.darabszamEf),
							ertekEf: formatCurrency(value.ertekEf),
							darabszamVf: formatPiece(value.darabszamVf),
							ertekVf: formatCurrency(value.ertekVf),
							ertekOssz: formatCurrency(value.ertekUnc + value.ertekEf + value.ertekVf),
							osszesitesSzint: value.osszesitesSzint
						},
						children: []
					})
					if (expandedKeys[penznemItem.key]) {
						delete expandedKeys[penznemItem.key]
					} else {
						expandedKeys[penznemItem.key] = true
					}
					setExpandedKeys(expandedKeys)
					treeNodes.slice(-1)[0].children = [...treeNodes.slice(-1)[0].children, penznemItem]
				} else if (value.osszesitesSzint === 'SOROZAT_ERTEKKEL' || value.osszesitesSzint === 'SOROZAT_ERTEK_NELKUL') {
					let penznemItem = ({
						key: index + '-2',
						data: {
							sorozat: value.sorozatDto.nev,
							sorrend: value.sorozatDto.sorrend,
							darabszamUnc: formatPiece(value.darabszamUnc),
							ertekUnc: formatCurrency(value.ertekUnc),
							darabszamEf: formatPiece(value.darabszamEf),
							ertekEf: formatCurrency(value.ertekEf),
							darabszamVf: formatPiece(value.darabszamVf),
							ertekVf: formatCurrency(value.ertekVf),
							ertekOssz: formatCurrency(value.ertekUnc + value.ertekEf + value.ertekVf)
						},
						children: []
					})
					treeNodes.slice(-1)[0].children.slice(-1)[0].children = [...treeNodes.slice(-1)[0].children.slice(-1)[0].children, penznemItem]
				}
			})
			setTreeItems(treeNodes)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryListBankjegyOsszesites])
	const darabszamUncTemplate = (rowData) => {
		return !rowData.data.ertekUnc ? <span className="font-italic">{rowData.data.darabszamUnc}</span> : rowData.data.darabszamUnc
	}
	const darabszamEfTemplate = (rowData) => {
		return !rowData.data.ertekEf ? <span className="font-italic">{rowData.data.darabszamEf}</span> : rowData.data.darabszamEf
	}
	const darabszamVfTemplate = (rowData) => {
		return !rowData.data.ertekVf ? <span className="font-italic">{rowData.data.darabszamVf}</span> : rowData.data.darabszamVf
	}
	const tableRef = useRef(null)
	const {onDownload} = useDownloadExcel({
		currentTableRef: tableRef.current?.getElement(),
		filename: `gyujtoi_statisztika_${moment().format("YYYYMMDDHHmmss")}`,
		sheet: 'Gyüjtői statisztika'
	})

	return <AdminPanel className="bankjegy-osszesites-content" stretch label="Gyűjtői statisztika">
		<div>
			{queryListBankjegyOsszesites.loading && <ProgressBar className="mt-3" mode="indeterminate"/>}
			{!queryListBankjegyOsszesites.loading && queryListBankjegyOsszesites.data &&
			<>
				<div className="d-flex justify-content-end mb-1">
					<Button icon='pi pi-file-excel' title='Excel export' onClick={onDownload}/>
				</div>
				<TreeTable ref={tableRef} expandedKeys={expandedKeys} onToggle={e => setExpandedKeys(e.value)} rowClassName={rowData => {
					return {'teljes': rowData.data.osszesitesSzint === 'TELJES', 'penznem': rowData.data.osszesitesSzint === 'PENZNEM'}
				}} value={treeItems} emptyMessage="Nincs megjeleníthető adat!">
					<Column expander field="penznem" header="Pénznem"/>
					<Column field="sorozat" header="Sorozat"/>
					<Column className="text-end" field="darabszamUnc" header="Darabszám UNC" body={darabszamUncTemplate}/>
					<Column className="text-end" field="ertekUnc" header="Érték UNC"/>
					<Column className="text-end" field="darabszamEf" header="Darabszám EF" body={darabszamEfTemplate}/>
					<Column className="text-end" field="ertekEf" header="Érték EF"/>
					<Column className="text-end" field="darabszamVf" header="Darabszám VF" body={darabszamVfTemplate}/>
					<Column className="text-end" field="ertekVf" header="Érték VF"/>
					<Column className="text-end" field="ertekOssz" header="Érték (UNC+EF+VF)"/>
				</TreeTable>
			</>}
		</div>
		<div className="col mt-3">
			<Button
				onClick={() => {
					history.push('/')
				}}
				className="brown-button ml-1"
				label="Bezár"
				type="button"/>
		</div>
	</AdminPanel>
}

export default BankjegyOsszesitesPage
