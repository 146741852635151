import UserHeaderPanel from '../routes/user/UserHeaderPanel'
import {Panel} from 'primereact/panel'
import React from 'react'
import {Logo} from './Menu'

const AdminPanel = ({className, label, stretch, children}) => {
	return <div className={`user-page-content main-page-content mb-3 ${className ? className : ''}`}>
		<UserHeaderPanel theme="black" logo={<Logo/>}/>
		<div className={`container-fluid mt-3 pt-5 px-3 col-12 ${stretch ? '' : 'col-md-6'}`}>
			<Panel>
				<h1 className="mb-2 mt-2">{label}</h1>
				{children}
			</Panel>
		</div>
	</div>
}

export default AdminPanel