import React, {useContext} from 'react';
import {HashRouter, Switch} from 'react-router-dom'
import MainPage from "./routes/main/MainPage";
import DefaultLayout from "./layout/DefaultLayout";
import ListPage from "./routes/main/ListPage";
import BankjegyPage from "./routes/main/BankjegyPage";
import ErrorPage from "./routes/ErrorPage";
import UserActivation from "./routes/user/UserActivation";
import ForgottenPassword from "./routes/user/ForgottenPassword";
import BankjegyLayout from "./layout/BankjegyLayout";
import AdminLayout from "./layout/AdminLayout";
import UsersPage from "./routes/admin/Users";
import User from "./routes/admin/User";
import {UserContext, UserContextProvider} from "./components/UserContext";
import LogsPage from "./routes/admin/LogsPage";
import PasswordForm from "./routes/user/PasswordForm";
import RegistrationPage from "./routes/user/RegistrationPage";
import ForgottenPasswordRequestPage from "./routes/user/ForgottenPasswordRequestPage";
import LoginPage from "./routes/user/LoginPage";
import BankjegyOsszesitesPage from "./routes/main/BankjegyOsszesitesPage";
import ImpressPage from "./routes/admin/ImpressPage";
import EmailPage from "./routes/admin/EmailPage";
import ContactPage from "./routes/admin/ContactPage";
import MegjegyzesOsszesitesPage from './routes/main/MegjegyzesOsszesitesPage'
import GyujtemenyemPage from './routes/main/GyujtemenyemPage'
import ArvaltozasOsszesitesPage from './routes/main/ArvaltozasOsszesitesPage'

const SwitchRoute = () => {
    const userContext = useContext(UserContext);
    return <Switch>
        <DefaultLayout exact path='/' component={MainPage}/>
        <DefaultLayout path='/bejelentkezes' component={LoginPage}/>
        <DefaultLayout path='/regisztracio' component={RegistrationPage}/>
        <DefaultLayout path='/elfelejtett_jelszo' component={ForgottenPasswordRequestPage}/>
        {userContext.loggedInUser ?
            <AdminLayout path='/impresszum' component={ImpressPage} /> :
            <DefaultLayout path='/impresszum' component={ImpressPage} />
        }
        <BankjegyLayout path='/lista/:penznem?/:sorozatId?' component={ListPage} />
        <BankjegyLayout path='/bankjegy/:sorozatId/:id' component={BankjegyPage}  />
        <BankjegyLayout path='/bankjegy_szerkesztes/:sorozatId/:id?' component={BankjegyPage}  />
        <DefaultLayout path='/felhasznalo_aktivalas/:id/:kod'component={UserActivation} />
        <DefaultLayout path='/jelszocsere/:id/:kod'component={ForgottenPassword} />
        <AdminLayout path='/felhasznalok'component={UsersPage} />
        <AdminLayout path='/sajatadatok'component={User} />
        <AdminLayout path='/jelszomodositas'component={PasswordForm} />
        <AdminLayout path='/naplo' component={LogsPage} />
        <AdminLayout path='/levelkuldes' component={EmailPage} />
        <AdminLayout path='/kapcsolat' component={ContactPage} />
        <AdminLayout path='/gyujtoistatisztika' component={BankjegyOsszesitesPage} />
        <AdminLayout path='/megjegyzesstatisztika' component={MegjegyzesOsszesitesPage} />
        <AdminLayout path='/arvaltozasstatisztika' component={ArvaltozasOsszesitesPage} />
        <AdminLayout path='/gyujtemenyem' component={GyujtemenyemPage} />
        <AdminLayout component={ErrorPage} />
    </Switch>
}


function BankjegyApp() {
  return (
      <HashRouter>
          <UserContextProvider>
            <SwitchRoute/>
          </UserContextProvider>
      </HashRouter>
  )
}

export default BankjegyApp;
