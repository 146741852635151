import React, {useContext, useEffect, useRef} from 'react'
import {withRouter} from "react-router";
import {useMutation} from "@apollo/client";
import {Logo} from "../../layout/Menu";
import {Panel} from "primereact/panel";
import imgError from "../../images/error-128.png";
import {createErrorMessage} from "./LoginPage";
import {Link} from "react-router-dom";
import gql from "graphql-tag";
import {Formik} from "formik";
import {Button} from "primereact/button";
import {ProgressBar} from "primereact/progressbar";
import {Messages} from "primereact/messages";
import {SuccessMessagePanel} from "./RegistrationPage";
import PasswordEditor from '../../components/editors/PasswordEditor'
import * as Yup from 'yup';
import {setLocale} from 'yup';
import {UserContext} from "../../components/UserContext";

const FORGOTTEN_PASSWORD_CHECK = gql`
    mutation ForgottenPasswordCheck($elfelejtettJelszoEllenorzes: ElfelejtettJelszoEllenorzes!) {
        forgottenPasswordCheck(elfelejtettJelszoEllenorzes: $elfelejtettJelszoEllenorzes)
    }
`

const FORGOTTEN_PASSWORD = gql`
    mutation ForgottenPassword($elfelejtettJelszo: ElfelejtettJelszo!) {
    forgottenPassword(elfelejtettJelszo: $elfelejtettJelszo)
    }
`

const SuccessForgottenPasswordPanel = () => (
	<>
		<SuccessMessagePanel
			title="Sikeres jelszó módosítás!"
			desc="A megadott új jelszóval bejelentkezhet!"
		/>
	</>
)

const ForgottenPasswordForm = ({id, kod}) => {
	const messageRef = useRef(null)
	const [forgottenPassword, { data, loading: mutationLoading, error: mutationError }] = useMutation(FORGOTTEN_PASSWORD);

	/* eslint-disable no-template-curly-in-string */
	setLocale({
		mixed: {
			required: 'A mező kitöltése kötelező!',
		},
		string: {
			min: 'A ${label} mezőnek minimum ${min} karakter hosszúnak kell lennie!',
			max: 'A ${label} mezőnek maximum ${max} karakter hosszúnak lehet lennie!',
			matches: 'Nem megfelelő ${label}! pl.: nev@email.hu'
		}
	})

	const passwordValidationSchema = Yup.object().shape({
		password: Yup.string().min(8).max(255).required().label('jelszó'),
		password2: Yup.string().required().oneOf([Yup.ref('password'), null], 'Nem egyeznek a beírt jelszavak!').label('jelszó'),
	})

	return (
		<>
			{(data && data.forgottenPassword === 'SUCCESS') ?
				<>
					<SuccessForgottenPasswordPanel/>
					<div className='row mb-2 mt-3'>
						<div className='col-12 col-md-12 cursor-pointer text-center'>
							<Link to="/">Bejelentkezés</Link>
						</div>
					</div>
				</> :
				<Formik
					initialValues={{password: '', password2: ''}}
					validationSchema={passwordValidationSchema}
					onSubmit={(values, {setSubmitting}) => {
						forgottenPassword({
							variables: {
								"elfelejtettJelszo": {
									id: id,
									token: kod,
									jelszo1: values.password,
									jelszo2: values.password2,
								}
							}
						}).then()
					}}
				>
					{({
						  values,
						  errors,
						  touched,
						  handleChange,
						  handleBlur,
						  handleSubmit,
						  isSubmitting,
						  setFieldValue
						  /* and other goodies */
					  }) => (
						<form className='form-forgotten-password'
							onSubmit={handleSubmit}
						>
							<div className="mb-3">Kérem, adja meg az jelszavát!</div>
							<div className='row mb-2'>
								<div className='col-12 col-md-12'>
									<PasswordEditor focus fieldName='password' placeholder='jelszó' />
								</div>
							</div>
							<div className='row mb-2'>
								<div className='col-12 col-md-12'>
									<PasswordEditor fieldName='password2' placeholder='jelszó ismét' />
								</div>
							</div>
							<div className='row top10'>
								<div className='col-12 col-md-12'>
									<Button
										disabled={Object.keys(errors).length > 0 || mutationLoading}
										className=""
										label='Jelszó módosítás'
										type='submit'/>
								</div>
							</div>
							<div className="mt-3 mb-2 text-center">
								<Link to="/">Vissza a nyilólapra</Link>
							</div>
							{mutationLoading && <ProgressBar className="mt-3" mode="indeterminate"/>}
							{createErrorMessage(mutationError, 'Sikertelen jelszó módosítás!', messageRef, 20000)}
							<Messages className="mt-3" ref={messageRef}/>
						</form>
					)}
				</Formik>
			}
		</>
	)
}

const ForgottenPasswordRequestError = ({data, mutationError}) => {
	return (
			<>
				<div className="d-flex align-items-center">
					<div className="col-auto p-0">
						<img src={imgError} alt="error"/>
					</div>
					<div className="col p-0 px-3">
					  <span className="font-weight-bold">
						A jelszó módosítása nem sikerült!
					  </span><br/>
						{mutationError ?
							createErrorMessage( mutationError) :
							'Kérem ellenőrizze, hogy helyesen adta-e meg a linket!'}
					</div>
				</div>
				<div className="mt-3 mb-2 text-center">
					<Link to="/">Vissza a nyilólapra</Link>
				</div>
			</>
	)
}

const ForgottenPassword = ({ children, match, ...rest }) => {
	const userContext = useContext(UserContext)
	if (userContext.loggedInUser) {
		userContext.logoutUser()
	}
	const [forgottenPasswordCheck, { data, error: mutationError }] = useMutation(FORGOTTEN_PASSWORD_CHECK, {
		variables: {
			"elfelejtettJelszoEllenorzes": {
				"id": match.params.id,
				"token": match.params.kod
			}}});

	useEffect(()=>{
		forgottenPasswordCheck().then()
	}, [forgottenPasswordCheck]) //ha azt akarjuk, hogy csak 1x fusson le -miután az oldal betöltődött- akkor kell az üres tömb 2. paraméternek
	return (
		<div className="main-page-content forgotten-password-page">
			<div className="d-flex h-100 justify-content-center flex-column">
				<div className="logo-wrapper d-flex justify-content-center">
					<div className="d-flex flex-column align-self-center">
						<div className="col"><Logo/></div>
						<div className="col mt-4">
							<Panel>
								{(data && data.forgottenPasswordCheck === 'SUCCESS') &&
									<ForgottenPasswordForm id={match.params.id} kod={match.params.kod}/> }
								{mutationError &&
									<ForgottenPasswordRequestError data={data} mutationError={mutationError}/>}
							</Panel>
						</div>
					</div>
				</div>
			</div>
		</div>

	)
}

export default withRouter(ForgottenPassword)
