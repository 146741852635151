import React, {useContext, useState} from 'react'
import gql from 'graphql-tag'
import {useMutation, useQuery} from '@apollo/client'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {formatDateTime} from '../Common'
import {getEnumValue} from '../EnumUtils'
import {UserContext} from '../../components/UserContext'
import imgDelete16 from '../../images/trash-w-16.png'
import {Button} from 'primereact/button'
import {useHistory} from 'react-router'
import {Dialog} from 'primereact/dialog'
import AdminPanel from '../../layout/AdminPanel'

const LIST_FELHASZNALOK = gql`
    query ListFelhasznalok {
        listFelhasznalok {
            id
            email
            nev
            telefonszam
            szerep
            gyujto
            utolsoBelepesIdeje
            regisztracioIdeje
        }
    }
`

const DELETE_USER_BY_ADMIN = gql`
    mutation DeleteUserByAdmin($id:ID!) {
        deleteUserByAdmin(id:$id)
    }
`

export const LabelValue = ({label, value, className}) => {
	return (
		<div className={`d-flex label-value-comp col-12 flex-wrap ${className ? className : ''}`}>
			<label htmlFor="labelValue" className="col-3 col-form-label">{label}:</label>
			<div className="col-9">
				{value && <span key={`input_${value}`} className="form-control-plaintext">{value}</span>}
			</div>
		</div>
	)
}

const UsersPage = () => {
	const history = useHistory()
	const queryListFelhasznalok = useQuery(LIST_FELHASZNALOK, {fetchPolicy: 'network-only'})
	const szerepTemplate = (rowData) => {
		return getEnumValue('Szerep', rowData.szerep)
	}
	const utolsoBelepesIdejeTemplate = (rowData) => {
		return formatDateTime(rowData.utolsoBelepesIdeje)
	}
	const regisztracioIdejeTemplate = (rowData) => {
		return formatDateTime(rowData.regisztracioIdeje)
	}
	const gyujtoTemplate = (rowData) => {
		return rowData.gyujto === true ? 'igen' : 'nem'
	}

	const userContext = useContext(UserContext)
	const [showUserModel, setShowUserModel] = useState(false)
	const onModalClose = () => {
		setShowUserModel(false)
	}

	const iconTemplate = (rowData) => {
		return (
			<>
				{rowData.szerep !== 'ADMIN' && <button className="red-button" onClick={() => {
					setSelectedUser(rowData)
					setShowUserModel(true)
				}}><img src={imgDelete16} alt="delete user"/></button>}
			</>
		)
	}

	const [selectedUser, setSelectedUser] = useState(null)

	const [deleteUserByAdmin] = useMutation(DELETE_USER_BY_ADMIN, {
		variables: {id: selectedUser && selectedUser.id},
		onCompleted() {
			userContext.growl({severity: 'info', summary: 'A felhasznaló adatai sikeresen törlésre kerültek!'})
		},
		refetchQueries: [{
			query: LIST_FELHASZNALOK
		}]
	})

	return <AdminPanel label="Felhasználók" stretch>
		<Dialog
			header="Törlés"
			visible={showUserModel}
			onHide={onModalClose}>
			<div className="">
				<div>
					Biztosan törli a felhasználót?<br/>
					<div className="row">
						<LabelValue label="Név" value={selectedUser && selectedUser.nev}/>
						<LabelValue label="E-mail" value={selectedUser && selectedUser.email}/>
					</div>
				</div>
				<div className="buttons mt-3">
					<Button label="Igen"
						type="button"
						className="green-button"
						onClick={e => {
							deleteUserByAdmin().then()
							setShowUserModel(false)
						}}/>
					<Button label="Nem"
						className="brown-button ml-1"
						type="button"
						onClick={e => {
							setShowUserModel(false)
						}}/>
				</div>
			</div>
		</Dialog>
		<div>
			{userContext.loggedInUser.szerep !== 'ADMIN' ?
				<div className="alert alert-warning" role="alert">Nincs megfelelő jogosultsága!</div> :
				<DataTable autoLayout={true} className="w-100" stripedRows
					value={queryListFelhasznalok.data ? queryListFelhasznalok.data.listFelhasznalok : []}>
					<Column className="icon-column" body={iconTemplate}/>
					<Column sortable field="email" header="E-mail"/>
					<Column sortable field="nev" header="Név"/>
					<Column field="telefonszam" header="Telefonszám"/>
					<Column sortable field="szerep" header="Szerep" body={szerepTemplate}/>
					<Column sortable field="gyujto" header="Gyűjtői felületek megjelenítése" body={gyujtoTemplate}/>
					<Column sortable field="utolsoBelepesIdeje" header="Utolso belépés ideje" body={utolsoBelepesIdejeTemplate}/>
					<Column sortable field="regisztracioIdeje" header="Regisztráció ideje" body={regisztracioIdejeTemplate}/>
				</DataTable>
			}
		</div>
		<div className="buttons mt-3">
			<Button
				onClick={(e) => {
					history.push('/')
				}}
				className="brown-button ml-1"
				label="Bezár"
				type="button"/>
		</div>
	</AdminPanel>
}

export default UsersPage
